import { toast } from '@/shared/components';
import { useEmployeeDetailContext } from '@/shared/contexts';
import { useDraftProbationReviewForRevieweeMutation } from '@/shared/graphql';
import { ProbationReviewDetailType } from '@/shared/types';
import { vestResolver } from '@hookform/resolvers/vest';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { ProbationDetails } from '../probation-details';
import { revieweeFormValidationSuite } from './reviewee-form.validator';

export const RevieweeForm = () => {
  const { employeeDetail } = useEmployeeDetailContext();

  const form = useForm<ProbationReviewDetailType>({
    defaultValues: employeeDetail.probationReview ?? undefined,
    resolver: vestResolver(revieweeFormValidationSuite),
  });

  useEffect(() => {
    form.reset(employeeDetail.probationReview ?? undefined);
  }, [employeeDetail.probationReview, form]);

  const [draftProbationReviewForReviewee, { loading: draftLoading }] =
    useDraftProbationReviewForRevieweeMutation({
      onCompleted() {
        toast.success();
      },
    });

  const handleSaveDraft = async () => {
    const data = form.getValues();
    await draftProbationReviewForReviewee({
      variables: {
        input: {
          employeeId: employeeDetail.id,
          target: data.target,
          stages: data.stages.map((stage) => ({
            selfFeedback: stage.selfFeedback,
          })),
        },
      },
    });
  };

  return (
    <FormProvider {...form}>
      <ProbationDetails
        onSaveDraft={handleSaveDraft}
        draftLoading={draftLoading}
      />
    </FormProvider>
  );
};
