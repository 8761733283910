import { useMemo } from 'react';

import { TableSelection, TypeOfAttritionType } from '@/shared/types';
import { DateUtils } from '@/shared/utils';
import { GreenCheckIcon, RedCrossIcon } from '../../../shared/icons';
import { NoResult } from '../../../shared/no-result';
import { Table, TableLayout, TableProps } from '../../../shared/table';
import { TypeOfAttritionModal } from '../type-of-attrition-modal';

interface TypeOfAttritionTableProps {
  loading: boolean;
  items: TypeOfAttritionType[];
  selection: TableSelection<TypeOfAttritionType>;
  setSelection: (selection: TableSelection<TypeOfAttritionType>) => void;
  total: number;
}

export const TypeOfAttritionTable = ({
  items,
  loading,
  total,
  selection,
}: TypeOfAttritionTableProps) => {
  const layout = useMemo(
    () =>
      [
        {
          headerRender: () => 'Name',
          accessor: 'name',
          cellRender: (typeOfAttrition: TypeOfAttritionType) => {
            return (
              <TypeOfAttritionModal typeOfAttrition={typeOfAttrition}>
                <span className='text-blue-6 hover:cursor-pointer'>
                  {typeOfAttrition.name}
                </span>
              </TypeOfAttritionModal>
            );
          },
        },
        {
          headerRender: () => 'Enabled',
          accessor: 'enabled',
          cellRender: ({ enabled }: TypeOfAttritionType) => {
            return (
              <span>{enabled ? <GreenCheckIcon /> : <RedCrossIcon />}</span>
            );
          },
        },
        {
          headerRender: () => 'Created Date',
          accessor: 'createdDate',
          cellRender: ({ createdAt }: TypeOfAttritionType) => {
            return <span>{DateUtils.formatDatetime(createdAt)}</span>;
          },
        },
        {
          headerRender: () => 'Updated Date',
          accessor: 'updatedAt',
          cellRender: ({ updatedAt }: TypeOfAttritionType) => {
            return <span>{DateUtils.formatDatetime(updatedAt)}</span>;
          },
        },
      ] as TableLayout<TypeOfAttritionType>[],
    []
  );

  const tableCommonProps: TableProps<TypeOfAttritionType> = {
    layout,
    loading,
    data: items || [],
    notFoundTemplate: (
      <NoResult
        title={'There are no Business Unit Item Title'}
        description={'There are no Business Unit Item Desc'}
      />
    ),
  };

  return (
    <>
      <Table<TypeOfAttritionType>
        {...tableCommonProps}
        selection={selection}
        page={1}
        allowFloatingHeader
      />
    </>
  );
};
