import {
  ContractType,
  EmployeeHiringSource,
  EmployeeStatus,
  JobHistoryLevel,
} from '@/shared/graphql';
import { z } from 'zod';
import { EmployeeDirectReport } from './employee-direct-reports';

export const EmployeeGeneralInformation = z.object({
  id: z.string().default(''),
  preferredFullName: z.string().default(''),
  legalFullName: z.string().default(''),
  nameInLocalLanguage: z.string().nullable().default(null),
  pictureUrl: z.string().nullable().default(null),
  employeeId: z.string().nullable().default(null),
  employeeCode: z.string().nullable().default(null),
  effectiveDateUntil: z.string().nullable().default(null),
  status: z.nativeEnum(EmployeeStatus).default(EmployeeStatus.Active),
  email: z.string().nullable().default(null),
  hiredDate: z.string().nullable().default(null),
  contractType: z.nativeEnum(ContractType).default(ContractType.FtPermanent),
  officeLocation: z.string().default(''),
  company: z.string().default(''),
  companyEmail: z.string().default(''),
  businessUnit: z.string().default(''),
  department: z.string().default(''),
  job: z.string().default(''),
  jobTitle: z.string().default(''),
  team: z.string().nullable().default(null),
  level: z.nativeEnum(JobHistoryLevel).default(JobHistoryLevel.L0),
  emailUsername: z.string().nullable().default(null),
  emailDomain: z.string().nullable().default(null),
  emailAddress: z.string().nullable().default(null),
  primaryManagerId: z.string().default(''),
  primaryManager: z.string().default(''),
  dottedLineManagerId: z.string().default(''),
  dottedLineManager: z.string().default(''),
  nationalityId: z.string().default(''),
  nationality: z.string().default(''),
  location: z.string().default(''),
  country: z.string().default(''),
  hiringSource: z.nativeEnum(EmployeeHiringSource).nullable().default(null),
  isGoogleEnabled: z.boolean().default(false),
  isSlackEnabled: z.boolean().default(false),
  isHubspotEnabled: z.boolean().default(false),
  isGreenhouseEnabled: z.boolean().default(false),
  needsProbationReview: z.boolean().default(false),
  isNewGraduate: z.boolean().default(false),
  isOnSecondment: z.boolean().default(false),
  lastWorkingDate: z.string().nullable().default(null),
  terminationDate: z.string().nullable().default(null),
  typeOfAttrition: z.string().catch(''),
  reasonForLeaving: z.string().catch(''),
  directReports: z.array(EmployeeDirectReport).catch([]),
  greenhouseLink: z.string().nullable().default(null),
});
export type EmployeeGeneralInformationType = z.infer<
  typeof EmployeeGeneralInformation
>;
