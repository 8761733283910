import { ContractType, JobHistoryLevel } from '@/shared/graphql';
import { z } from 'zod';
import { PerformanceReviewStatus, Quarter } from '../../../types';

export const PerformanceReviewListParser = z.object({
  year: z.string().optional().default(new Date().getFullYear().toString()),
  quarter: z.nativeEnum(Quarter).optional().catch(Quarter.Q3_Q4),
  page: z.number().catch(1),
  keyword: z.string().nullish().catch(''),
  performanceReviewStatuses: z
    .array(z.nativeEnum(PerformanceReviewStatus))
    .optional()
    .catch([]),
  contractTypes: z.array(z.nativeEnum(ContractType)).optional().catch([]),
  companyIds: z.array(z.string()).optional().catch([]),
  locationIds: z.array(z.string()).optional().catch([]),
  businessUnitIds: z.array(z.string()).optional().catch([]),
  departmentIds: z.array(z.string()).optional().catch([]),
  levels: z.array(z.nativeEnum(JobHistoryLevel)).optional().catch([]),
  showDirectReports: z.boolean().optional().catch(false),
});

export type PerformanceReviewListFilterType = z.infer<
  typeof PerformanceReviewListParser
>;
