import { useFilters } from '@/shared/hooks';
import { Quarter } from 'src/pages/performance-review/types';
import {
  PerformanceReviewListFilterType,
  PerformanceReviewListParser,
} from './PerformanceReviewList.filters';

export const usePerformanceReviewListFilter = () => {
  const filters = useFilters<PerformanceReviewListFilterType>(
    {
      year: new Date().getFullYear().toString(),
      quarter: Quarter.Q3_Q4,
      keyword: '',
      performanceReviewStatuses: [],
      contractTypes: [],
      companyIds: [],
      locationIds: [],
      businessUnitIds: [],
      departmentIds: [],
      levels: [],
      showDirectReports: false,
      page: 1,
    },
    {
      parser: PerformanceReviewListParser.parse,
    }
  );

  return { filters };
};
