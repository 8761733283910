import { PageTitle } from '@/shared/components';
import { useNavigate } from 'react-router-dom';

export const PerformanceReviewEdit = () => {
  const navigate = useNavigate();

  return (
    <>
      <PageTitle
        text='Edit Performance Review'
        onBack={() => navigate('/performance-review')}
      />
      <div>Performance Review Edit here</div>
    </>
  );
};
