// TODO: Temporary while waiting for schema

import { ContractType, JobHistoryLevel } from '@/shared/graphql';
import { z } from 'zod';

export enum Quarter {
  Q1_Q2 = '1Q/2Q',
  Q3_Q4 = '3Q/4Q',
}

export enum PerformanceReviewStatus {
  TARGET_SETTING = 'Target Setting',
  TARGET_EVALUATION = 'Target Evaluation',
  CLOSED_FOR_EDITS = 'Closed for Edits',
}

export const PerformanceReviewListItem = z.object({
  employeeId: z.string().default(''),
  employeeName: z.string().default(''),
  performanceReviewStatus: z.nativeEnum(PerformanceReviewStatus),
  contractType: z.nativeEnum(ContractType),
  totalTargetWeight: z.number().default(0),
  probationEndDate: z.string().default(''),
  level: z.nativeEnum(JobHistoryLevel),
  companyName: z.string().default(''),
  officeLocation: z.string().default(''),
  businessUnit: z.string().default(''),
  department: z.string().default(''),
  jobTitle: z.string().default(''),
});
export type PerformanceReviewListItemType = z.infer<
  typeof PerformanceReviewListItem
>;
