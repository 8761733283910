import { useEmployeeDetailContext } from '@/shared/contexts';
import { ProbationReviewStatus } from '@/shared/graphql';
import { Button } from '@any-ui-react/core';
import { EMPTY_DEFAULT } from 'src/components/shared';
import { ProbationReviewStages } from '../review-stages';
import { TotalTarget } from '../total-target';
import { SubmitModal } from './SubmitModal';

interface Props {
  onSaveDraft: () => void;
  draftLoading?: boolean;
}

export const ProbationDetails = ({ onSaveDraft, draftLoading }: Props) => {
  const { employeeDetail } = useEmployeeDetailContext();
  const isSubmitted =
    employeeDetail.probationReview?.status === ProbationReviewStatus.Submitted;

  return (
    <div className='flex flex-col'>
      <TotalTarget
        isSubmitted={isSubmitted}
        hiredDate={employeeDetail.generalInformation.hiredDate}
        probationEndDate={
          employeeDetail.probationReview?.stages.length
            ? employeeDetail.probationReview.stages.slice(-1)[0].deadline
            : EMPTY_DEFAULT
        }
        result={employeeDetail.probationReview?.result}
      />

      <ProbationReviewStages isSubmitted={isSubmitted} />

      {!isSubmitted && (
        <div className='my-6 flex justify-end space-x-4 bg-white px-6 py-4'>
          <Button
            variant='outline'
            onClick={onSaveDraft}
            loading={draftLoading}
          >
            Save as Draft
          </Button>

          <SubmitModal />
        </div>
      )}
    </div>
  );
};
