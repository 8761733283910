import { EmployeeRole, useGetCurrentEmployeeQuery } from '@/shared/graphql';
import { useMemo } from 'react';
import { useAuthContext, useEmployeeDetailContext } from '../../contexts';

export const useEmployeeAccess = () => {
  const { authUser } = useAuthContext();
  const { data, loading: currentUserLoading } = useGetCurrentEmployeeQuery();
  const { employeeDetail } = useEmployeeDetailContext();

  const isSuperAdmin = useMemo(
    () => authUser.role === EmployeeRole.SuperAdmin,
    [authUser.role]
  );
  const isAdminAdvanced = useMemo(
    () => authUser.role === EmployeeRole.AdminPrivate,
    [authUser.role]
  );
  const isAdminStandard = useMemo(
    () => authUser.role === EmployeeRole.AdminStandard,
    [authUser.role]
  );
  const isMyOwnData = useMemo(
    () => employeeDetail.id === data?.currentEmployee.id,
    [data?.currentEmployee.id, employeeDetail.id]
  );

  const isDirectReport = useMemo(() => {
    const isPrimaryManager =
      employeeDetail.generalInformation.primaryManagerId ===
      data?.currentEmployee.id;
    const isDottedLineManager =
      employeeDetail.generalInformation.dottedLineManagerId ===
      data?.currentEmployee.id;
    return isPrimaryManager || isDottedLineManager;
  }, [
    data?.currentEmployee.id,
    employeeDetail.generalInformation.dottedLineManagerId,
    employeeDetail.generalInformation.primaryManagerId,
  ]);

  const isAssignedCompany = useMemo(() => {
    const authenticatedEmployeeCompanyIds =
      data?.currentEmployee.permission?.companyIds ?? [];
    const employeeCompanyIds = employeeDetail.permission.companyIds;

    return authenticatedEmployeeCompanyIds.some((companyId) =>
      employeeCompanyIds.includes(companyId)
    );
  }, [
    data?.currentEmployee.permission?.companyIds,
    employeeDetail.permission.companyIds,
  ]);

  const isAnAssignor = useMemo(
    () =>
      data?.currentEmployee.permission?.employeeIds.findIndex(
        (item) => item === employeeDetail.generalInformation.id
      ) !== -1,
    [
      data?.currentEmployee.permission?.employeeIds,
      employeeDetail.generalInformation.id,
    ]
  );

  const hasGeneralInformationEditAccess = useMemo(() => {
    const validTypes =
      [EmployeeRole.Basic, EmployeeRole.Advanced].includes(authUser.role) ===
      false;

    return (
      isSuperAdmin ||
      ((isMyOwnData || isDirectReport || isAnAssignor || isAssignedCompany) &&
        validTypes)
    );
  }, [
    authUser.role,
    isAnAssignor,
    isDirectReport,
    isMyOwnData,
    isSuperAdmin,
    isAssignedCompany,
  ]);

  const hasPersonalInformationViewAccess = useMemo(() => {
    const validTypes =
      [EmployeeRole.Basic, EmployeeRole.Advanced].includes(authUser.role) ===
      false;

    return (
      isSuperAdmin ||
      isMyOwnData ||
      ((isAnAssignor || isDirectReport || isAssignedCompany) && validTypes)
    );
  }, [
    authUser.role,
    isSuperAdmin,
    isMyOwnData,
    isAnAssignor,
    isDirectReport,
    isAssignedCompany,
  ]);

  const hasPersonalInformationEditAccess = useMemo(() => {
    const validTypes =
      [EmployeeRole.Basic, EmployeeRole.Advanced].includes(authUser.role) ===
      false;

    return (
      isSuperAdmin ||
      ((isMyOwnData || isAnAssignor || isDirectReport || isAssignedCompany) &&
        validTypes)
    );
  }, [
    authUser.role,
    isAnAssignor,
    isMyOwnData,
    isSuperAdmin,
    isDirectReport,
    isAssignedCompany,
  ]);

  const hasPersonalInformationDeleteAccess = useMemo(() => {
    return isSuperAdmin;
  }, [isSuperAdmin]);

  const hasOffBoardingViewAccess = useMemo(() => {
    return (
      isSuperAdmin ||
      isAdminAdvanced ||
      (isAdminStandard && isAssignedCompany && !isMyOwnData)
    );
  }, [
    isSuperAdmin,
    isAdminAdvanced,
    isAdminStandard,
    isAssignedCompany,
    isMyOwnData,
  ]);

  const hasJobContractHistoryViewAccess = useMemo(() => {
    const validTypes =
      [EmployeeRole.Basic, EmployeeRole.Advanced].includes(authUser.role) ===
      false;
    return (
      isSuperAdmin ||
      isMyOwnData ||
      ((isDirectReport || isAnAssignor || isAssignedCompany) && validTypes)
    );
  }, [
    authUser.role,
    isSuperAdmin,
    isMyOwnData,
    isDirectReport,
    isAnAssignor,
    isAssignedCompany,
  ]);

  const hasPermissionViewAccess = useMemo(() => {
    return (
      isSuperAdmin ||
      (isAdminAdvanced && (isAssignedCompany || isDirectReport || isMyOwnData))
    );
  }, [
    isSuperAdmin,
    isAdminAdvanced,
    isAssignedCompany,
    isDirectReport,
    isMyOwnData,
  ]);

  const hasPermissionEditAccess = useMemo(() => {
    return hasPermissionViewAccess && !isMyOwnData;
  }, [hasPermissionViewAccess, isMyOwnData]);

  const hasNotificationPageAccess = useMemo(() => {
    if (!(isSuperAdmin || isAdminAdvanced || isAdminStandard)) {
      return false;
    }

    return !!data?.currentEmployee.currentContract?.company.countryId;
  }, [
    data?.currentEmployee.currentContract?.company.countryId,
    isAdminAdvanced,
    isAdminStandard,
    isSuperAdmin,
  ]);

  const hasProbationReviewViewAccess = useMemo(() => {
    return isMyOwnData || isDirectReport || isAssignedCompany || isSuperAdmin;
  }, [isAssignedCompany, isDirectReport, isMyOwnData, isSuperAdmin]);

  const hasProbationReviewModifyAccess = useMemo(() => {
    return (
      isMyOwnData ||
      isSuperAdmin ||
      isDirectReport ||
      (isAdminAdvanced && isAssignedCompany)
    );
  }, [
    isAdminAdvanced,
    isAssignedCompany,
    isDirectReport,
    isMyOwnData,
    isSuperAdmin,
  ]);

  return {
    hasEmployeeDeleteAccess: isSuperAdmin,
    hasGeneralInformationEditAccess,
    hasPersonalInformationViewAccess,
    hasPersonalInformationEditAccess,
    hasPersonalInformationDeleteAccess,
    hasOffBoardingViewAccess,
    hasOffBoardingEditAccess: hasOffBoardingViewAccess,
    hasJobContractHistoryViewAccess, // reuse variable, as same logic
    hasJobContractHistoryEditAccess: hasPersonalInformationEditAccess,
    hasJobContractHistoryDeleteAccess: hasPersonalInformationDeleteAccess,
    hasExternalAccountsViewAccess: hasOffBoardingViewAccess,
    hasPermissionViewAccess,
    hasPermissionEditAccess,
    loading: currentUserLoading,
    hasNotificationCountryAccess: isSuperAdmin,
    hasNotificationPageAccess,
    hasProbationReviewViewAccess,
    hasProbationReviewModifyAccess,
    isMyOwnData,
    currentContractCountryId:
      data?.currentEmployee.currentContract?.company.countryId,
  };
};
