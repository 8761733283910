import { Select, SelectProps } from '@any-ui-react/core';
import { Quarter } from 'src/pages/performance-review/types';

interface QuarterSelectProps extends Omit<SelectProps, 'data'> {}

export const QuarterSelect = ({ ...props }: QuarterSelectProps) => {
  const types = Object.values(Quarter).map((value) => ({
    label: value,
    value: value,
  }));

  return <Select data={types} {...props} />;
};
