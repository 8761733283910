import { Card, DEFAULT_PAGE_SIZE, PageTitle } from '@/shared/components';
import { TablePagination } from '@any-ui-react/core';
import { useWindowScroll } from '@mantine/hooks';
import {
  PerformanceReviewListFilter,
  PerformanceReviewListTable,
  usePerformanceReviewListFilter,
} from './components';
import { performanceReviewListMockData } from './data.mock';

export const PerformanceReviewList = () => {
  const [, scrollTo] = useWindowScroll();
  const { filters } = usePerformanceReviewListFilter();
  const { page, ...currentFilter } = filters.current;

  console.log(currentFilter);

  // TODO:! query here

  return (
    <>
      <PageTitle text='Performance Review' />
      <Card withPadding={false}>
        <PerformanceReviewListFilter filters={filters} />
        <PerformanceReviewListTable
          data={performanceReviewListMockData}
          loading={false}
          page={1}
        />
      </Card>
      <TablePagination
        className='px-4 py-3 lg:px-0'
        value={1}
        rowsPerPage={DEFAULT_PAGE_SIZE}
        count={performanceReviewListMockData.length}
        onChange={(newPage) => {
          filters.changeFilter('page', newPage, false);
          scrollTo({ y: 0 });
        }}
      />
    </>
  );
};
