import { ProbationReviewResult, ProbationReviewStatus } from '@/shared/graphql';
import { z } from 'zod';

export const ProbationReviewDetail = z.object({
  status: z
    .nativeEnum(ProbationReviewStatus)
    .default(ProbationReviewStatus.Draft),
  target: z.string().default(''),
  employeeId: z.string().default(''),
  result: z
    .nativeEnum(ProbationReviewResult)
    .nullable()
    .default(ProbationReviewResult.Hold),
  stages: z
    .array(
      z.object({
        reviewerId: z.string().default(''),
        deadline: z.string().default(''),
        reviewerFeedback: z.string().nullable().default(''),
        selfFeedback: z.string().nullable().default(''),
        deletable: z.boolean().default(false),
        reviewerName: z.string().optional().default(''),
      })
    )
    .default([]),
});

export type ProbationReviewDetailType = z.infer<typeof ProbationReviewDetail>;
