export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  BigDecimal: { input: any; output: any; }
  Date: { input: any; output: any; }
  DateTime: { input: any; output: any; }
  Url: { input: any; output: any; }
  Year: { input: any; output: any; }
  YearMonth: { input: any; output: any; }
};

/** Business unit. */
export type BusinessUnit = {
  __typename?: 'BusinessUnit';
  createdAt: Scalars['DateTime']['output'];
  division: Division;
  divisionId: Scalars['ID']['output'];
  enabled: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

/** Output type for a business unit. */
export type BusinessUnitOutput = {
  __typename?: 'BusinessUnitOutput';
  businessUnit: BusinessUnit;
  id: Scalars['ID']['output'];
};

/** Company. */
export type Company = {
  __typename?: 'Company';
  country: Country;
  countryId: Scalars['ID']['output'];
  createdAt: Scalars['DateTime']['output'];
  enabled: Scalars['Boolean']['output'];
  googleOrgUnitPath: Scalars['String']['output'];
  hrEmailAddress?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  probationReviewTerm: CompanyProbationReviewTerm;
  probationReviewTimes: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

/** Company email domain. */
export type CompanyEmailDomain = {
  __typename?: 'CompanyEmailDomain';
  createdAt: Scalars['DateTime']['output'];
  disableExternalAccount: Scalars['Boolean']['output'];
  enabled: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

/** Output type for a company domain email. */
export type CompanyEmailDomainOutput = {
  __typename?: 'CompanyEmailDomainOutput';
  companyEmailDomain: CompanyEmailDomain;
  id: Scalars['ID']['output'];
};

/** Output type for a company. */
export type CompanyOutput = {
  __typename?: 'CompanyOutput';
  company: Company;
  id: Scalars['ID']['output'];
};

/** Company - probation review term. */
export type CompanyProbationReviewTerm = {
  __typename?: 'CompanyProbationReviewTerm';
  length: Scalars['Int']['output'];
  mode: CompanyProbationReviewTermMode;
};

/** Company - probation review term. */
export type CompanyProbationReviewTermInput = {
  length: Scalars['Int']['input'];
  mode: CompanyProbationReviewTermMode;
};

export enum CompanyProbationReviewTermMode {
  Day = 'DAY',
  Month = 'MONTH'
}

export enum ContractType {
  Contractor = 'CONTRACTOR',
  FtConsultant = 'FT_CONSULTANT',
  FtFixedTerm = 'FT_FIXED_TERM',
  FtFreelancer = 'FT_FREELANCER',
  FtPermanent = 'FT_PERMANENT',
  Intern = 'INTERN',
  PartTime = 'PART_TIME'
}

export enum ContractTypeForReport {
  FtConsultant = 'FT_CONSULTANT',
  FtFixedTerm = 'FT_FIXED_TERM',
  FtFreelancer = 'FT_FREELANCER',
  FtPermanent = 'FT_PERMANENT',
  Intern = 'INTERN',
  PartTime = 'PART_TIME'
}

/** Country. */
export type Country = {
  __typename?: 'Country';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** International dialing code in format +[1-3 digits]. */
  isdCode?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

/** Input type to create a business unit. */
export type CreateBusinessUnitInput = {
  divisionId: Scalars['ID']['input'];
  enabled: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
};

/** Input type to create a company domain email. */
export type CreateCompanyEmailDomainInput = {
  disableExternalAccount: Scalars['Boolean']['input'];
  enabled: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
};

/** Input type to create a company. */
export type CreateCompanyInput = {
  countryId: Scalars['ID']['input'];
  enabled: Scalars['Boolean']['input'];
  googleOrgUnitPath: Scalars['String']['input'];
  name: Scalars['String']['input'];
  probationReviewTerm: CompanyProbationReviewTermInput;
  probationReviewTimes: Scalars['Int']['input'];
};

/** Input type to create a department. */
export type CreateDepartmentInput = {
  enabled: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
};

/** Input type to create a division. */
export type CreateDivisionInput = {
  enabled: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
};

/** Input type to create an employee's personal information - company asset. */
export type CreateEmployeeCompanyAssetInput = {
  assignedDate: Scalars['Date']['input'];
  employeeId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  number: Scalars['String']['input'];
  returnedDate?: InputMaybe<Scalars['Date']['input']>;
  type: EmployeeCompanyAssetType;
};

/** Input type to create an employee contract */
export type CreateEmployeeContractInput = {
  companyId: Scalars['ID']['input'];
  contractType: ContractType;
  effectiveDate: Scalars['Date']['input'];
  effectiveDateUntil?: InputMaybe<Scalars['Date']['input']>;
  employeeCode?: InputMaybe<Scalars['String']['input']>;
  employeeId: Scalars['ID']['input'];
  isMain: Scalars['Boolean']['input'];
  onSecondment: Scalars['Boolean']['input'];
};

/** Input data type to create an employee - current contract. */
export type CreateEmployeeCurrentContractInput = {
  companyId: Scalars['ID']['input'];
  contractEndDate?: InputMaybe<Scalars['Date']['input']>;
  contractType: ContractType;
  employeeCode?: InputMaybe<Scalars['String']['input']>;
};

/** Input data type to create an employee - current job history. */
export type CreateEmployeeCurrentJobHistoryInput = {
  businessUnitId: Scalars['ID']['input'];
  countryId: Scalars['ID']['input'];
  departmentId: Scalars['ID']['input'];
  jobId: Scalars['ID']['input'];
  jobTitle: Scalars['String']['input'];
  level: JobHistoryLevel;
  locationId: Scalars['ID']['input'];
  regionalRole: Scalars['Boolean']['input'];
  team?: InputMaybe<Scalars['String']['input']>;
};

/** Input type to create an employee's personal information - dependent. */
export type CreateEmployeeDependentInput = {
  dateOfBirth: Scalars['Date']['input'];
  employeeId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  relation: EmployeeRelationType;
};

/** Input type to create an employee's personal information - emergency contact. */
export type CreateEmployeeEmergencyContactInput = {
  employeeId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  phoneNumber: PhoneNumberInput;
  relation: EmployeeRelationType;
  remarks?: InputMaybe<Scalars['String']['input']>;
};

/** Input data type to create an employee - external account. */
export type CreateEmployeeExternalAccountAutoCreationInput = {
  autoCreationSettings: CreateExternalAccountAutoCreationSettingsInput;
  timingToCreate: ExternalAccountTimingToCreate;
};

/** Input data type to create an employee - general information. */
export type CreateEmployeeGeneralInformationInput = {
  companyEmail: EmployeeCompanyEmailInput;
  dottedLineManagerId?: InputMaybe<Scalars['ID']['input']>;
  greenhouseLink: Scalars['Url']['input'];
  hiredDate: Scalars['Date']['input'];
  hiringSource: EmployeeHiringSource;
  isNewGraduate: Scalars['Boolean']['input'];
  legalFullName: Scalars['String']['input'];
  nameInLocalLanguage?: InputMaybe<Scalars['String']['input']>;
  nationalityId: Scalars['ID']['input'];
  needsProbationReview: Scalars['Boolean']['input'];
  preferredFullName: Scalars['String']['input'];
  primaryManagerId: Scalars['ID']['input'];
};

/** Input type to create an employee. */
export type CreateEmployeeInput = {
  currentContract: CreateEmployeeCurrentContractInput;
  currentJobHistory: CreateEmployeeCurrentJobHistoryInput;
  externalAccount: CreateEmployeeExternalAccountAutoCreationInput;
  generalInformation: CreateEmployeeGeneralInformationInput;
  permission: CreateEmployeePermissionInput;
  personalInformation: CreateEmployeePersonalInformationInput;
};

/** Input type to create an employee job history */
export type CreateEmployeeJobHistoryInput = {
  businessUnitId: Scalars['ID']['input'];
  departmentId: Scalars['ID']['input'];
  effectiveDate: Scalars['Date']['input'];
  effectiveDateUntil?: InputMaybe<Scalars['Date']['input']>;
  employeeId: Scalars['ID']['input'];
  jobId: Scalars['ID']['input'];
  jobTitle: Scalars['String']['input'];
  level: JobHistoryLevel;
  locationId: Scalars['ID']['input'];
  /** Note for extended leave. This is ignored when `type` is not for extended leave. */
  note?: InputMaybe<Scalars['String']['input']>;
  regionalRole: Scalars['Boolean']['input'];
  team?: InputMaybe<Scalars['String']['input']>;
  type: JobHistoryType;
};

/** Input type to create an employee list custom filter. */
export type CreateEmployeeListCustomFilterInput = {
  businessUnitIds: Array<Scalars['ID']['input']>;
  companyIds: Array<Scalars['ID']['input']>;
  contractTypes: Array<ContractType>;
  departmentIds: Array<Scalars['ID']['input']>;
  divisionIds: Array<Scalars['ID']['input']>;
  employmentEndDate?: InputMaybe<LocalDateRangeInput>;
  externalAccountGoogle: Array<EmployeeExternalAccountStatus>;
  externalAccountGreenhouse: Array<EmployeeExternalAccountStatus>;
  externalAccountHubspot: Array<EmployeeExternalAccountStatus>;
  externalAccountSlack: Array<EmployeeExternalAccountStatus>;
  hiredDate?: InputMaybe<LocalDateRangeInput>;
  isLeave: Scalars['Boolean']['input'];
  isOnSecondment: Scalars['Boolean']['input'];
  isOpenFilter: Scalars['Boolean']['input'];
  jobIds: Array<Scalars['ID']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  levels: Array<JobHistoryLevel>;
  locationIds: Array<Scalars['ID']['input']>;
  managerIds: Array<Scalars['ID']['input']>;
  managerIncludesAllConnections: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  nationalityIds: Array<Scalars['ID']['input']>;
  roles: Array<EmployeeRole>;
  statuses: Array<EmployeeStatus>;
};

/** Input type to create an employee's permission. */
export type CreateEmployeePermissionInput = {
  companyIds: Array<Scalars['ID']['input']>;
  employeeIds: Array<Scalars['ID']['input']>;
  role: EmployeeRole;
};

/** Input type to create an employee - personal information - company asset. */
export type CreateEmployeePersonalInformationCompanyAssetInput = {
  assignedDate: Scalars['Date']['input'];
  name: Scalars['String']['input'];
  number: Scalars['String']['input'];
  returnedDate?: InputMaybe<Scalars['Date']['input']>;
  type: EmployeeCompanyAssetType;
};

/** Input type to create an employee - personal information - dependent. */
export type CreateEmployeePersonalInformationDependentInput = {
  dateOfBirth: Scalars['Date']['input'];
  name: Scalars['String']['input'];
  relation: EmployeeRelationType;
};

/** Input type to create an employee - personal information - emergency contact. */
export type CreateEmployeePersonalInformationEmergencyContactInput = {
  name: Scalars['String']['input'];
  phoneNumber: PhoneNumberInput;
  relation: EmployeeRelationType;
  remarks?: InputMaybe<Scalars['String']['input']>;
};

/** Input type to create an employee - personal information. */
export type CreateEmployeePersonalInformationInput = {
  bank?: InputMaybe<EmployeeBankInput>;
  companyAssets: Array<CreateEmployeePersonalInformationCompanyAssetInput>;
  consultingFeeBank?: InputMaybe<EmployeeConsultingFeeBankInput>;
  currentAddress?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth: Scalars['Date']['input'];
  dependents: Array<CreateEmployeePersonalInformationDependentInput>;
  emergencyContacts?: InputMaybe<Array<CreateEmployeePersonalInformationEmergencyContactInput>>;
  gender: EmployeeGender;
  idExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  idNumber?: InputMaybe<Scalars['String']['input']>;
  idType?: InputMaybe<EmployeeIdType>;
  maritalStatus?: InputMaybe<EmployeeMaritalStatus>;
  nhiDependents?: InputMaybe<Scalars['Int']['input']>;
  passportExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  passportName?: InputMaybe<Scalars['String']['input']>;
  passportNumber?: InputMaybe<Scalars['String']['input']>;
  passportPlaceOfBirth?: InputMaybe<Scalars['String']['input']>;
  pensionVoluntaryContributionRate?: InputMaybe<Scalars['String']['input']>;
  permanentAddress?: InputMaybe<Scalars['String']['input']>;
  personalEmail?: InputMaybe<Scalars['String']['input']>;
  personalPhoneNumber?: InputMaybe<PhoneNumberInput>;
  residentialCountryId: Scalars['ID']['input'];
  socialInsuranceNotes?: InputMaybe<Scalars['String']['input']>;
  socialInsuranceNumber?: InputMaybe<Scalars['String']['input']>;
  taxCode?: InputMaybe<Scalars['String']['input']>;
  taxWithholdingRate?: InputMaybe<Scalars['String']['input']>;
  visas?: InputMaybe<Array<CreateEmployeePersonalInformationVisaInput>>;
};

/** Input type to create an employee - personal information - visa. */
export type CreateEmployeePersonalInformationVisaInput = {
  visaExpiryDate: Scalars['Date']['input'];
  visaIssuedDate: Scalars['Date']['input'];
  visaIssuedPlace: Scalars['String']['input'];
  visaNumber: Scalars['String']['input'];
  visaType: Scalars['String']['input'];
  workPermitExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitNumber?: InputMaybe<Scalars['String']['input']>;
};

/** Input type to create an employee's personal information - visa. */
export type CreateEmployeeVisaInput = {
  employeeId: Scalars['ID']['input'];
  visaExpiryDate: Scalars['Date']['input'];
  visaIssuedDate: Scalars['Date']['input'];
  visaIssuedPlace: Scalars['String']['input'];
  visaNumber: Scalars['String']['input'];
  visaType: Scalars['String']['input'];
  workPermitExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitNumber?: InputMaybe<Scalars['String']['input']>;
};

/** Input data type for the settings of automatic external account creation. */
export type CreateExternalAccountAutoCreationSettingsInput = {
  enableGoogle: Scalars['Boolean']['input'];
  enableGreenhouse: Scalars['Boolean']['input'];
};

/** Input type to create a job. */
export type CreateJobInput = {
  enabled: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
};

/** Input type to create a location. */
export type CreateLocationInput = {
  countryId?: InputMaybe<Scalars['ID']['input']>;
  enabled: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
};

/** Input type to create an off boarding - reason for leaving. */
export type CreateReasonForLeavingInput = {
  enabled: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
};

/** Input type to create an off boarding - type of attrition. */
export type CreateTypeOfAttritionInput = {
  enabled: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
};

/** Currency. */
export type Currency = {
  __typename?: 'Currency';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

/** Department. */
export type Department = {
  __typename?: 'Department';
  createdAt: Scalars['DateTime']['output'];
  enabled: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

/** Output type for a department. */
export type DepartmentOutput = {
  __typename?: 'DepartmentOutput';
  department: Department;
  id: Scalars['ID']['output'];
};

/** Division. */
export type Division = {
  __typename?: 'Division';
  createdAt: Scalars['DateTime']['output'];
  enabled: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

/** Output type for a division. */
export type DivisionOutput = {
  __typename?: 'DivisionOutput';
  division: Division;
  id: Scalars['ID']['output'];
};

/** Input type to draft a probation review for reviewee. */
export type DraftProbationReviewForRevieweeInput = {
  employeeId: Scalars['ID']['input'];
  stages: Array<DraftProbationReviewStageForRevieweeInput>;
  target?: InputMaybe<Scalars['String']['input']>;
};

/** Input type to draft a probation review for reviewer. */
export type DraftProbationReviewForReviewerInput = {
  employeeId: Scalars['ID']['input'];
  stages: Array<DraftProbationReviewStageForReviewerInput>;
  target?: InputMaybe<Scalars['String']['input']>;
};

/** Input type to draft a probation review stage for reviewee. */
export type DraftProbationReviewStageForRevieweeInput = {
  selfFeedback?: InputMaybe<Scalars['String']['input']>;
};

/** Input type to draft a probation review stage for reviewer. */
export type DraftProbationReviewStageForReviewerInput = {
  deadline: Scalars['Date']['input'];
  reviewerFeedback?: InputMaybe<Scalars['String']['input']>;
  reviewerId?: InputMaybe<Scalars['ID']['input']>;
};

/** Employee. */
export type Employee = {
  __typename?: 'Employee';
  contractNote?: Maybe<Scalars['String']['output']>;
  contracts: Array<EmployeeContract>;
  createdAt: Scalars['DateTime']['output'];
  currentContract?: Maybe<EmployeeContract>;
  currentPosition?: Maybe<EmployeeJobHistory>;
  directReports: Array<Employee>;
  externalAccounts: Array<EmployeeExternalAccount>;
  generalInformation: EmployeeGeneralInformation;
  id: Scalars['ID']['output'];
  jobHistories: Array<EmployeeJobHistory>;
  jobHistoryNote?: Maybe<Scalars['String']['output']>;
  managers: Array<Employee>;
  offBoarding?: Maybe<EmployeeOffBoarding>;
  permission?: Maybe<EmployeePermission>;
  personalInformation?: Maybe<EmployeePersonalInformation>;
  pictureUrl?: Maybe<Scalars['Url']['output']>;
  probationReview?: Maybe<ProbationReview>;
  status: EmployeeStatus;
  updatedAt: Scalars['DateTime']['output'];
};

/** Employee - bank. */
export type EmployeeBank = EmployeeBankCommon & {
  __typename?: 'EmployeeBank';
  accountHolderName?: Maybe<Scalars['String']['output']>;
  accountNumber?: Maybe<Scalars['String']['output']>;
  branchName?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Country>;
  countryId?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** Employee - bank (common information). */
export type EmployeeBankCommon = {
  accountHolderName?: Maybe<Scalars['String']['output']>;
  accountNumber?: Maybe<Scalars['String']['output']>;
  branchName?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Country>;
  countryId?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** Input type for an employee - bank. */
export type EmployeeBankInput = {
  accountHolderName?: InputMaybe<Scalars['String']['input']>;
  accountNumber?: InputMaybe<Scalars['String']['input']>;
  branchName?: InputMaybe<Scalars['String']['input']>;
  countryId?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Employee - company asset. */
export type EmployeeCompanyAsset = {
  __typename?: 'EmployeeCompanyAsset';
  assignedDate: Scalars['Date']['output'];
  employeeId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  number: Scalars['String']['output'];
  returnedDate?: Maybe<Scalars['Date']['output']>;
  type: EmployeeCompanyAssetType;
};

/** Output type for an employee's personal information - company asset. */
export type EmployeeCompanyAssetOutput = {
  __typename?: 'EmployeeCompanyAssetOutput';
  companyAsset: EmployeeCompanyAsset;
  id: Scalars['ID']['output'];
};

export enum EmployeeCompanyAssetType {
  Adapter = 'ADAPTER',
  Display = 'DISPLAY',
  Others = 'OTHERS',
  Pc = 'PC'
}

/** Employee - company email. */
export type EmployeeCompanyEmail = {
  __typename?: 'EmployeeCompanyEmail';
  account: Scalars['String']['output'];
  domainId: Scalars['ID']['output'];
  fullAddress: Scalars['String']['output'];
};

/** Input data type for employee - company email. */
export type EmployeeCompanyEmailInput = {
  account: Scalars['String']['input'];
  domainId: Scalars['ID']['input'];
};

/** Employee - consulting fee bank. */
export type EmployeeConsultingFeeBank = EmployeeBankCommon & {
  __typename?: 'EmployeeConsultingFeeBank';
  accountHolderName?: Maybe<Scalars['String']['output']>;
  accountNumber?: Maybe<Scalars['String']['output']>;
  branchName?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Country>;
  countryId?: Maybe<Scalars['ID']['output']>;
  currency?: Maybe<Currency>;
  currencyId?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** Input type for an employee - consulting fee bank. */
export type EmployeeConsultingFeeBankInput = {
  accountHolderName?: InputMaybe<Scalars['String']['input']>;
  accountNumber?: InputMaybe<Scalars['String']['input']>;
  branchName?: InputMaybe<Scalars['String']['input']>;
  countryId?: InputMaybe<Scalars['ID']['input']>;
  currencyId?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Employee - contract. */
export type EmployeeContract = {
  __typename?: 'EmployeeContract';
  company: Company;
  companyId: Scalars['ID']['output'];
  contractType: ContractType;
  effectiveDate: Scalars['Date']['output'];
  effectiveDateUntil?: Maybe<Scalars['Date']['output']>;
  employeeCode?: Maybe<Scalars['String']['output']>;
  employeeId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  isMain: Scalars['Boolean']['output'];
  onSecondment: Scalars['Boolean']['output'];
};

/** Output type for an employee contract. */
export type EmployeeContractOutput = {
  __typename?: 'EmployeeContractOutput';
  contract?: Maybe<EmployeeContract>;
  id: Scalars['ID']['output'];
};

/** Employee - dependent. */
export type EmployeeDependent = {
  __typename?: 'EmployeeDependent';
  age: Scalars['Int']['output'];
  dateOfBirth: Scalars['Date']['output'];
  employeeId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  relation: EmployeeRelationType;
};

/** Output type for an employee's personal information - dependent. */
export type EmployeeDependentOutput = {
  __typename?: 'EmployeeDependentOutput';
  dependent: EmployeeDependent;
  id: Scalars['ID']['output'];
};

/** Employee - emergency contact. */
export type EmployeeEmergencyContact = {
  __typename?: 'EmployeeEmergencyContact';
  employeeId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  phoneNumber: PhoneNumber;
  relation: EmployeeRelationType;
  remarks?: Maybe<Scalars['String']['output']>;
};

/** Output type for an employee's personal information - emergency contact. */
export type EmployeeEmergencyContactOutput = {
  __typename?: 'EmployeeEmergencyContactOutput';
  emergencyContact: EmployeeEmergencyContact;
  id: Scalars['ID']['output'];
};

export enum EmployeeExportFormat {
  All = 'ALL',
  ContractJobHistory = 'CONTRACT_JOB_HISTORY',
  CreateTemplate = 'CREATE_TEMPLATE',
  General = 'GENERAL',
  OffBoarding = 'OFF_BOARDING'
}

/** Employee - external account. */
export type EmployeeExternalAccount = {
  __typename?: 'EmployeeExternalAccount';
  accountType: EmployeeExternalAccountType;
  email: Scalars['String']['output'];
  employeeId: Scalars['ID']['output'];
  status: EmployeeExternalAccountStatus;
  userType: EmployeeExternalAccountUserType;
};

export enum EmployeeExternalAccountStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  NotExisting = 'NOT_EXISTING'
}

export enum EmployeeExternalAccountType {
  Google = 'GOOGLE',
  Greenhouse = 'GREENHOUSE',
  Hubspot = 'HUBSPOT',
  Slack = 'SLACK'
}

export enum EmployeeExternalAccountUserType {
  GoogleDelegatedAdmin = 'GOOGLE_DELEGATED_ADMIN',
  GoogleSuperAdmin = 'GOOGLE_SUPER_ADMIN',
  GreenhouseSiteAdmin = 'GREENHOUSE_SITE_ADMIN',
  HubspotSuperAdmin = 'HUBSPOT_SUPER_ADMIN',
  Member = 'MEMBER',
  SlackAdmin = 'SLACK_ADMIN',
  SlackOwner = 'SLACK_OWNER',
  SlackPrimaryOwner = 'SLACK_PRIMARY_OWNER'
}

export enum EmployeeGender {
  Female = 'FEMALE',
  Male = 'MALE',
  Other = 'OTHER'
}

/** Employee - general information. */
export type EmployeeGeneralInformation = {
  __typename?: 'EmployeeGeneralInformation';
  companyEmail: EmployeeCompanyEmail;
  dottedLineManager?: Maybe<Employee>;
  dottedLineManagerId?: Maybe<Scalars['ID']['output']>;
  greenhouseLink?: Maybe<Scalars['Url']['output']>;
  hiredDate: Scalars['Date']['output'];
  hiringSource?: Maybe<EmployeeHiringSource>;
  isNewGraduate: Scalars['Boolean']['output'];
  legalFullName: Scalars['String']['output'];
  nameInLocalLanguage?: Maybe<Scalars['String']['output']>;
  nationality: Country;
  nationalityId: Scalars['ID']['output'];
  needsProbationReview: Scalars['Boolean']['output'];
  preferredFullName: Scalars['String']['output'];
  primaryManager?: Maybe<Employee>;
  primaryManagerId?: Maybe<Scalars['ID']['output']>;
};

export enum EmployeeHiringSource {
  Agency = 'AGENCY',
  CompanyWebsite = 'COMPANY_WEBSITE',
  JobPosting = 'JOB_POSTING',
  Referral = 'REFERRAL',
  SocialMedia = 'SOCIAL_MEDIA',
  Sourcing = 'SOURCING'
}

export enum EmployeeIdType {
  DriverLicense = 'DRIVER_LICENSE',
  NationalId = 'NATIONAL_ID'
}

/** Employee - job history. */
export type EmployeeJobHistory = {
  __typename?: 'EmployeeJobHistory';
  businessUnit: BusinessUnit;
  businessUnitId: Scalars['ID']['output'];
  department: Department;
  departmentId: Scalars['ID']['output'];
  effectiveDate: Scalars['Date']['output'];
  effectiveDateUntil?: Maybe<Scalars['Date']['output']>;
  employeeId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  job: Job;
  jobId: Scalars['ID']['output'];
  jobTitle: Scalars['String']['output'];
  level: JobHistoryLevel;
  location: Location;
  locationId: Scalars['ID']['output'];
  /** Note for extended leave. This will be always null when `type` is not for extended leave. */
  note?: Maybe<Scalars['String']['output']>;
  regionalRole: Scalars['Boolean']['output'];
  team?: Maybe<Scalars['String']['output']>;
  type: JobHistoryType;
};

/** Output type for an employee job history. */
export type EmployeeJobHistoryOutput = {
  __typename?: 'EmployeeJobHistoryOutput';
  id: Scalars['ID']['output'];
  jobHistory: EmployeeJobHistory;
};

/** Employee list custom filter. */
export type EmployeeListCustomFilter = {
  __typename?: 'EmployeeListCustomFilter';
  businessUnitIds: Array<Scalars['ID']['output']>;
  businessUnits: Array<BusinessUnit>;
  companies: Array<Company>;
  companyIds: Array<Scalars['ID']['output']>;
  contractTypes: Array<ContractType>;
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<Employee>;
  createdById?: Maybe<Scalars['ID']['output']>;
  departmentIds: Array<Scalars['ID']['output']>;
  departments: Array<Department>;
  divisionIds: Array<Scalars['ID']['output']>;
  divisions: Array<Division>;
  employmentEndDate: LocalDateRange;
  externalAccountGoogle: Array<EmployeeExternalAccountStatus>;
  externalAccountGreenhouse: Array<EmployeeExternalAccountStatus>;
  externalAccountHubspot: Array<EmployeeExternalAccountStatus>;
  externalAccountSlack: Array<EmployeeExternalAccountStatus>;
  hiredDate: LocalDateRange;
  id: Scalars['ID']['output'];
  isLeave: Scalars['Boolean']['output'];
  isOnSecondment: Scalars['Boolean']['output'];
  isOpenFilter: Scalars['Boolean']['output'];
  jobIds: Array<Scalars['ID']['output']>;
  jobs: Array<Job>;
  keyword?: Maybe<Scalars['String']['output']>;
  levels: Array<JobHistoryLevel>;
  locationIds: Array<Scalars['ID']['output']>;
  locations: Array<Location>;
  managerIds: Array<Scalars['ID']['output']>;
  managerIncludesAllConnections: Scalars['Boolean']['output'];
  managers: Array<Employee>;
  name: Scalars['String']['output'];
  nationalities: Array<Country>;
  nationalityIds: Array<Scalars['ID']['output']>;
  roles: Array<EmployeeRole>;
  statuses: Array<EmployeeStatus>;
  updatedAt: Scalars['DateTime']['output'];
  updatedBy?: Maybe<Employee>;
  updatedById?: Maybe<Scalars['ID']['output']>;
};

/** Output type for an employee list custom filter. */
export type EmployeeListCustomFilterOutput = {
  __typename?: 'EmployeeListCustomFilterOutput';
  employeeListCustomFilter: EmployeeListCustomFilter;
  id: Scalars['ID']['output'];
};

export enum EmployeeListSortField {
  CreatedAt = 'CREATED_AT',
  Department = 'DEPARTMENT',
  EmploymentEndDate = 'EMPLOYMENT_END_DATE',
  HiredDate = 'HIRED_DATE',
  LastWorkingDate = 'LAST_WORKING_DATE',
  Level = 'LEVEL'
}

export enum EmployeeMaritalStatus {
  Married = 'MARRIED',
  Single = 'SINGLE'
}

/** Employee - off boarding. */
export type EmployeeOffBoarding = {
  __typename?: 'EmployeeOffBoarding';
  detailReasonForLeaving?: Maybe<Scalars['String']['output']>;
  doNotDeactivate: EmployeeOffBoardingDoNotDeactivate;
  employeeId: Scalars['ID']['output'];
  employmentEndDate?: Maybe<Scalars['Date']['output']>;
  lastWorkingDate?: Maybe<Scalars['Date']['output']>;
  reasonForLeaving?: Maybe<ReasonForLeaving>;
  reasonForLeavingId?: Maybe<Scalars['ID']['output']>;
  typeOfAttrition?: Maybe<TypeOfAttrition>;
  typeOfAttritionId?: Maybe<Scalars['ID']['output']>;
};

/** Employee - off boarding - do not deactivate. */
export type EmployeeOffBoardingDoNotDeactivate = {
  __typename?: 'EmployeeOffBoardingDoNotDeactivate';
  google: Scalars['Boolean']['output'];
  greenhouse: Scalars['Boolean']['output'];
};

/** Output type for an employee. */
export type EmployeeOutput = {
  __typename?: 'EmployeeOutput';
  employee: Employee;
  id: Scalars['ID']['output'];
};

/** Employee permission. */
export type EmployeePermission = {
  __typename?: 'EmployeePermission';
  accessSpecificEmployees: Scalars['Boolean']['output'];
  companies: Array<Company>;
  companyIds: Array<Scalars['ID']['output']>;
  employeeIds: Array<Scalars['ID']['output']>;
  employees: Array<Employee>;
  id: Scalars['ID']['output'];
  role: EmployeeRole;
};

/** Employee - personal information. */
export type EmployeePersonalInformation = {
  __typename?: 'EmployeePersonalInformation';
  bank?: Maybe<EmployeeBank>;
  companyAssets: Array<EmployeeCompanyAsset>;
  consultingFeeBank?: Maybe<EmployeeConsultingFeeBank>;
  currentAddress?: Maybe<Scalars['String']['output']>;
  dateOfBirth: Scalars['Date']['output'];
  dependencies: Array<EmployeeDependent>;
  emergencyContacts: Array<EmployeeEmergencyContact>;
  employeeId: Scalars['ID']['output'];
  gender: EmployeeGender;
  idExpiryDate?: Maybe<Scalars['Date']['output']>;
  idIssuedDate?: Maybe<Scalars['Date']['output']>;
  idNumber?: Maybe<Scalars['String']['output']>;
  idType?: Maybe<EmployeeIdType>;
  maritalStatus?: Maybe<EmployeeMaritalStatus>;
  nhiDependents?: Maybe<Scalars['Int']['output']>;
  passportExpiryDate?: Maybe<Scalars['Date']['output']>;
  passportIssuedDate?: Maybe<Scalars['Date']['output']>;
  passportIssuedPlace?: Maybe<Scalars['String']['output']>;
  passportName?: Maybe<Scalars['String']['output']>;
  passportNumber?: Maybe<Scalars['String']['output']>;
  pensionVoluntaryContributionRate?: Maybe<Scalars['String']['output']>;
  permanentAddress?: Maybe<Scalars['String']['output']>;
  personalEmail?: Maybe<Scalars['String']['output']>;
  personalPhoneNumber?: Maybe<PhoneNumber>;
  placeOfBirth?: Maybe<Scalars['String']['output']>;
  residentialCountryId: Scalars['ID']['output'];
  socialInsuranceNotes?: Maybe<Scalars['String']['output']>;
  socialInsuranceNumber?: Maybe<Scalars['String']['output']>;
  taxCode?: Maybe<Scalars['String']['output']>;
  taxWithholdingRate?: Maybe<Scalars['String']['output']>;
  visas: Array<EmployeeVisa>;
};

export enum EmployeeRelationType {
  Brother = 'BROTHER',
  Father = 'FATHER',
  Friend = 'FRIEND',
  Husband = 'HUSBAND',
  Mother = 'MOTHER',
  Other = 'OTHER',
  Relative = 'RELATIVE',
  Roommate = 'ROOMMATE',
  Sister = 'SISTER',
  Wife = 'WIFE'
}

export enum EmployeeRole {
  AdminPrivate = 'ADMIN_PRIVATE',
  AdminStandard = 'ADMIN_STANDARD',
  Advanced = 'ADVANCED',
  Basic = 'BASIC',
  SuperAdmin = 'SUPER_ADMIN'
}

export enum EmployeeStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  PreOnboarding = 'PRE_ONBOARDING'
}

/** Employee - visa. */
export type EmployeeVisa = {
  __typename?: 'EmployeeVisa';
  employeeId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  visaExpiryDate: Scalars['Date']['output'];
  visaIssuedDate: Scalars['Date']['output'];
  visaIssuedPlace: Scalars['String']['output'];
  visaNumber: Scalars['String']['output'];
  visaType: Scalars['String']['output'];
  workPermitExpiryDate?: Maybe<Scalars['Date']['output']>;
  workPermitIssuedDate?: Maybe<Scalars['Date']['output']>;
  workPermitNumber?: Maybe<Scalars['String']['output']>;
};

/** Output type for an employee's personal information - visa. */
export type EmployeeVisaOutput = {
  __typename?: 'EmployeeVisaOutput';
  id: Scalars['ID']['output'];
  visa: EmployeeVisa;
};

export enum ExternalAccountTimingToCreate {
  SevenDaysBefore = 'SEVEN_DAYS_BEFORE',
  Today = 'TODAY'
}

/** Job. */
export type Job = {
  __typename?: 'Job';
  createdAt: Scalars['DateTime']['output'];
  enabled: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export enum JobHistoryLevel {
  CClass = 'C_CLASS',
  Ic3 = 'IC3',
  Ic4 = 'IC4',
  Ic5 = 'IC5',
  Ic6 = 'IC6',
  Ic7 = 'IC7',
  L0 = 'L0',
  L1 = 'L1',
  L2 = 'L2',
  L3 = 'L3',
  L4 = 'L4',
  L5 = 'L5',
  L6 = 'L6',
  L7 = 'L7'
}

export enum JobHistoryType {
  ChangeContract = 'CHANGE_CONTRACT',
  ChangeJobDetails = 'CHANGE_JOB_DETAILS',
  ExtendedLeave = 'EXTENDED_LEAVE',
  Join = 'JOIN',
  ParentalLeave = 'PARENTAL_LEAVE',
  Promotion = 'PROMOTION',
  Transfer = 'TRANSFER'
}

/** Output type for a job. */
export type JobOutput = {
  __typename?: 'JobOutput';
  id: Scalars['ID']['output'];
  job: Job;
};

/** List of business units. */
export type ListBusinessUnits = ListMeta & {
  __typename?: 'ListBusinessUnits';
  items: Array<BusinessUnit>;
  totalCount: Scalars['Int']['output'];
};

/** List of companies. */
export type ListCompanies = ListMeta & {
  __typename?: 'ListCompanies';
  items: Array<Company>;
  totalCount: Scalars['Int']['output'];
};

/** List of company email domains. */
export type ListCompanyEmailDomains = ListMeta & {
  __typename?: 'ListCompanyEmailDomains';
  items: Array<CompanyEmailDomain>;
  totalCount: Scalars['Int']['output'];
};

/** List of countries. */
export type ListCountries = ListMeta & {
  __typename?: 'ListCountries';
  items: Array<Country>;
  totalCount: Scalars['Int']['output'];
};

/** List of currencies. */
export type ListCurrencies = ListMeta & {
  __typename?: 'ListCurrencies';
  items: Array<Currency>;
  totalCount: Scalars['Int']['output'];
};

/** List of departments. */
export type ListDepartments = ListMeta & {
  __typename?: 'ListDepartments';
  items: Array<Department>;
  totalCount: Scalars['Int']['output'];
};

/** List of divisions. */
export type ListDivisions = ListMeta & {
  __typename?: 'ListDivisions';
  items: Array<Division>;
  totalCount: Scalars['Int']['output'];
};

/** List of employee list custom filters. */
export type ListEmployeeListCustomFilters = ListMeta & {
  __typename?: 'ListEmployeeListCustomFilters';
  items: Array<EmployeeListCustomFilter>;
  totalCount: Scalars['Int']['output'];
};

/** List of employees. */
export type ListEmployees = ListMeta & {
  __typename?: 'ListEmployees';
  items: Array<Employee>;
  totalCount: Scalars['Int']['output'];
};

/** Filter for listing employees - External account. */
export type ListEmployeesFilterExternalAccountInput = {
  google?: InputMaybe<Array<EmployeeExternalAccountStatus>>;
  greenhouse?: InputMaybe<Array<EmployeeExternalAccountStatus>>;
  hubspot?: InputMaybe<Array<EmployeeExternalAccountStatus>>;
  slack?: InputMaybe<Array<EmployeeExternalAccountStatus>>;
};

/** Filter for listing employees. */
export type ListEmployeesFilterInput = {
  businessUnitIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  companyIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  contractTypes?: InputMaybe<Array<ContractType>>;
  departmentIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  divisionIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  employmentEndDate?: InputMaybe<LocalDateRangeInput>;
  excludeAuthUser?: InputMaybe<Scalars['Boolean']['input']>;
  externalAccount?: InputMaybe<ListEmployeesFilterExternalAccountInput>;
  hiredDate?: InputMaybe<LocalDateRangeInput>;
  isLeave?: InputMaybe<Scalars['Boolean']['input']>;
  isOnSecondment?: InputMaybe<Scalars['Boolean']['input']>;
  jobIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  levels?: InputMaybe<Array<JobHistoryLevel>>;
  locationIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  managerIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  managerIncludesAllConnections?: InputMaybe<Scalars['Boolean']['input']>;
  nationalityIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  roles?: InputMaybe<Array<EmployeeRole>>;
  statuses?: InputMaybe<Array<EmployeeStatus>>;
};

/** Sort for listing employees. */
export type ListEmployeesSortInput = {
  desc: Scalars['Boolean']['input'];
  field: EmployeeListSortField;
};

/** List of jobs. */
export type ListJobs = ListMeta & {
  __typename?: 'ListJobs';
  items: Array<Job>;
  totalCount: Scalars['Int']['output'];
};

/** List of locations. */
export type ListLocations = ListMeta & {
  __typename?: 'ListLocations';
  items: Array<Location>;
  totalCount: Scalars['Int']['output'];
};

export type ListMeta = {
  totalCount: Scalars['Int']['output'];
};

/** Filter for listing probation reviews. */
export type ListProbationReviewFilterInput = {
  businessUnitIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  companyIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  isOverdueOnly?: InputMaybe<Scalars['Boolean']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  levels?: InputMaybe<Array<JobHistoryLevel>>;
  primaryManagerIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  reviewerIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** List of probation review. */
export type ListProbationReviews = ListMeta & {
  __typename?: 'ListProbationReviews';
  items: Array<ProbationReview>;
  totalCount: Scalars['Int']['output'];
};

/** List of off boarding - reason for leaving. */
export type ListReasonForLeavings = ListMeta & {
  __typename?: 'ListReasonForLeavings';
  items: Array<ReasonForLeaving>;
  totalCount: Scalars['Int']['output'];
};

/** List of off boarding - type of attrition. */
export type ListTypeOfAttrition = ListMeta & {
  __typename?: 'ListTypeOfAttrition';
  items: Array<TypeOfAttrition>;
  totalCount: Scalars['Int']['output'];
};

/** Range of local date (yyyy-MM-dd). */
export type LocalDateRange = {
  __typename?: 'LocalDateRange';
  end?: Maybe<Scalars['Date']['output']>;
  start?: Maybe<Scalars['Date']['output']>;
};

/** Range of local date (yyyy-MM-dd). */
export type LocalDateRangeInput = {
  end?: InputMaybe<Scalars['Date']['input']>;
  start?: InputMaybe<Scalars['Date']['input']>;
};

/** Location. */
export type Location = {
  __typename?: 'Location';
  country?: Maybe<Country>;
  countryId?: Maybe<Scalars['ID']['output']>;
  createdAt: Scalars['DateTime']['output'];
  enabled: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

/** Output type for a location. */
export type LocationOutput = {
  __typename?: 'LocationOutput';
  id: Scalars['ID']['output'];
  location: Location;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Create a business unit. */
  createBusinessUnit: BusinessUnitOutput;
  /** Create a company. */
  createCompany: CompanyOutput;
  /** Create a company email domain. */
  createCompanyEmailDomain: CompanyEmailDomainOutput;
  /** Create a department. */
  createDepartment: DepartmentOutput;
  /** Create a division. */
  createDivision: DivisionOutput;
  /** Create an employee. */
  createEmployee: EmployeeOutput;
  /** Create an employee's personal information - company asset. */
  createEmployeeCompanyAsset: EmployeeCompanyAssetOutput;
  /** Create an employee's contract. */
  createEmployeeContract: EmployeeContractOutput;
  /** Create an employee's personal information - dependent. */
  createEmployeeDependent: EmployeeDependentOutput;
  /** Create an employee's personal information - emergency contact. */
  createEmployeeEmergencyContact: EmployeeEmergencyContactOutput;
  /** Create an employee job history. */
  createEmployeeJobHistory: EmployeeJobHistoryOutput;
  /** Create an employee list custom filter. */
  createEmployeeListCustomFilter: EmployeeListCustomFilterOutput;
  /** Create an employee's personal information - visa. */
  createEmployeeVisa: EmployeeVisaOutput;
  /** Create a job. */
  createJob: JobOutput;
  /** Create a location. */
  createLocation: LocationOutput;
  /** Create an off boarding - reason for leaving. */
  createReasonForLeaving: ReasonForLeavingOutput;
  /** Create an off boarding - type of attrition. */
  createTypeOfAttrition: TypeOfAttritionOutput;
  /** Delete an employee. */
  deleteEmployee: Scalars['Boolean']['output'];
  /** Delete an employee's personal information - company asset. */
  deleteEmployeeCompanyAsset: Scalars['Boolean']['output'];
  /** Delete an employee's contract. */
  deleteEmployeeContract: Scalars['Boolean']['output'];
  /** Delete an employee's personal information - dependent. */
  deleteEmployeeDependent: Scalars['Boolean']['output'];
  /** Delete an employee's personal information - emergency contact. */
  deleteEmployeeEmergencyContact: Scalars['Boolean']['output'];
  /** Delete an employee's job history. */
  deleteEmployeeJobHistory: Scalars['Boolean']['output'];
  /** Delete an employee list custom filter. */
  deleteEmployeeListCustomFilter: Scalars['Boolean']['output'];
  /** Delete an employee's personal information - visa. */
  deleteEmployeeVisa: Scalars['Boolean']['output'];
  /** Draft a probation review for reviewee. */
  draftProbationReviewForReviewee: ProbationReviewOutput;
  /** Draft a probation review for reviewer. */
  draftProbationReviewForReviewer: ProbationReviewOutput;
  /** Convert Google's authorization code to the token to access Google services. */
  googleOfflineOAuthCallback: Scalars['Boolean']['output'];
  /** Send invitation emails */
  sendInvitationEmails: Scalars['Boolean']['output'];
  /** Convert Google's authorization code to JWT to logging into MED. */
  signInCallback: Scalars['String']['output'];
  /** Submit a probation review. */
  submitProbationReview: ProbationReviewOutput;
  /** Update a business unit. */
  updateBusinessUnit: BusinessUnitOutput;
  /** Update a company. */
  updateCompany: CompanyOutput;
  /** Update a company email domain. */
  updateCompanyEmailDomain: CompanyEmailDomainOutput;
  /** Update a department. */
  updateDepartment: DepartmentOutput;
  /** Update a division. */
  updateDivision: DivisionOutput;
  /** Update an employee's personal information - company asset. */
  updateEmployeeCompanyAsset: EmployeeCompanyAssetOutput;
  /** Update an employee's contract. */
  updateEmployeeContract: EmployeeContractOutput;
  /** Update an employee's contract note. */
  updateEmployeeContractNote: EmployeeOutput;
  /** Update an employee's personal information - dependent. */
  updateEmployeeDependent: EmployeeDependentOutput;
  /** Update an employee's personal information - emergency contact. */
  updateEmployeeEmergencyContact: EmployeeEmergencyContactOutput;
  /** Update an employee's general information. */
  updateEmployeeGeneralInformation: EmployeeOutput;
  /** Update an employee's job history. */
  updateEmployeeJobHistory: EmployeeJobHistoryOutput;
  /** Update an employee's job history note. */
  updateEmployeeJobHistoryNote: EmployeeOutput;
  /** Update an employee list custom filter. */
  updateEmployeeListCustomFilter: EmployeeListCustomFilterOutput;
  /** Update an employee's off boarding. */
  updateEmployeeOffBoarding: EmployeeOutput;
  /** Update an employee's permission. */
  updateEmployeePermission: EmployeeOutput;
  /** Update an employee's personal information. */
  updateEmployeePersonalInformation: EmployeeOutput;
  /** Update an employee's personal information - visa. */
  updateEmployeeVisa: EmployeeVisaOutput;
  /** Update a job. */
  updateJob: JobOutput;
  /** Update a location. */
  updateLocation: LocationOutput;
  /** Update a notification setting. */
  updateNotificationSetting: NotificationSettingOutput;
  /** Update an off boarding - reason for leaving. */
  updateReasonForLeaving: ReasonForLeavingOutput;
  /** Update an off boarding - reason for leaving. */
  updateTypeOfAttrition: TypeOfAttritionOutput;
  /** Verify if the current user has an access token to call Google services. */
  verifyGoogleOfflineToken: Scalars['Boolean']['output'];
};


export type MutationCreateBusinessUnitArgs = {
  input: CreateBusinessUnitInput;
};


export type MutationCreateCompanyArgs = {
  input: CreateCompanyInput;
};


export type MutationCreateCompanyEmailDomainArgs = {
  input: CreateCompanyEmailDomainInput;
};


export type MutationCreateDepartmentArgs = {
  input: CreateDepartmentInput;
};


export type MutationCreateDivisionArgs = {
  input: CreateDivisionInput;
};


export type MutationCreateEmployeeArgs = {
  input: CreateEmployeeInput;
};


export type MutationCreateEmployeeCompanyAssetArgs = {
  input: CreateEmployeeCompanyAssetInput;
};


export type MutationCreateEmployeeContractArgs = {
  input: CreateEmployeeContractInput;
};


export type MutationCreateEmployeeDependentArgs = {
  input: CreateEmployeeDependentInput;
};


export type MutationCreateEmployeeEmergencyContactArgs = {
  input: CreateEmployeeEmergencyContactInput;
};


export type MutationCreateEmployeeJobHistoryArgs = {
  input: CreateEmployeeJobHistoryInput;
};


export type MutationCreateEmployeeListCustomFilterArgs = {
  input: CreateEmployeeListCustomFilterInput;
};


export type MutationCreateEmployeeVisaArgs = {
  input: CreateEmployeeVisaInput;
};


export type MutationCreateJobArgs = {
  input: CreateJobInput;
};


export type MutationCreateLocationArgs = {
  input: CreateLocationInput;
};


export type MutationCreateReasonForLeavingArgs = {
  input: CreateReasonForLeavingInput;
};


export type MutationCreateTypeOfAttritionArgs = {
  input: CreateTypeOfAttritionInput;
};


export type MutationDeleteEmployeeArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteEmployeeCompanyAssetArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteEmployeeContractArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteEmployeeDependentArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteEmployeeEmergencyContactArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteEmployeeJobHistoryArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteEmployeeListCustomFilterArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteEmployeeVisaArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDraftProbationReviewForRevieweeArgs = {
  input: DraftProbationReviewForRevieweeInput;
};


export type MutationDraftProbationReviewForReviewerArgs = {
  input: DraftProbationReviewForReviewerInput;
};


export type MutationGoogleOfflineOAuthCallbackArgs = {
  code: Scalars['String']['input'];
  redirectUri?: InputMaybe<Scalars['Url']['input']>;
};


export type MutationSendInvitationEmailsArgs = {
  input: SendInvitationEmailsInput;
};


export type MutationSignInCallbackArgs = {
  code: Scalars['String']['input'];
  redirectUri?: InputMaybe<Scalars['Url']['input']>;
};


export type MutationSubmitProbationReviewArgs = {
  input: SubmitProbationReviewInput;
};


export type MutationUpdateBusinessUnitArgs = {
  input: UpdateBusinessUnitInput;
};


export type MutationUpdateCompanyArgs = {
  input: UpdateCompanyInput;
};


export type MutationUpdateCompanyEmailDomainArgs = {
  input: UpdateCompanyEmailDomainInput;
};


export type MutationUpdateDepartmentArgs = {
  input: UpdateDepartmentInput;
};


export type MutationUpdateDivisionArgs = {
  input: UpdateDivisionInput;
};


export type MutationUpdateEmployeeCompanyAssetArgs = {
  input: UpdateEmployeeCompanyAssetInput;
};


export type MutationUpdateEmployeeContractArgs = {
  input: UpdateEmployeeContractInput;
};


export type MutationUpdateEmployeeContractNoteArgs = {
  input: UpdateEmployeeContractNoteInput;
};


export type MutationUpdateEmployeeDependentArgs = {
  input: UpdateEmployeeDependentInput;
};


export type MutationUpdateEmployeeEmergencyContactArgs = {
  input: UpdateEmployeeEmergencyContactInput;
};


export type MutationUpdateEmployeeGeneralInformationArgs = {
  input: UpdateEmployeeGeneralInformationInput;
};


export type MutationUpdateEmployeeJobHistoryArgs = {
  input: UpdateEmployeeJobHistoryInput;
};


export type MutationUpdateEmployeeJobHistoryNoteArgs = {
  input: UpdateEmployeeJobHistoryNoteInput;
};


export type MutationUpdateEmployeeListCustomFilterArgs = {
  input: UpdateEmployeeListCustomFilterInput;
};


export type MutationUpdateEmployeeOffBoardingArgs = {
  input: UpdateEmployeeOffBoardingInput;
};


export type MutationUpdateEmployeePermissionArgs = {
  input: UpdateEmployeePermissionInput;
};


export type MutationUpdateEmployeePersonalInformationArgs = {
  input: UpdateEmployeePersonalInformationInput;
};


export type MutationUpdateEmployeeVisaArgs = {
  input: UpdateEmployeeVisaInput;
};


export type MutationUpdateJobArgs = {
  input: UpdateJobInput;
};


export type MutationUpdateLocationArgs = {
  input: UpdateLocationInput;
};


export type MutationUpdateNotificationSettingArgs = {
  input: UpdateNotificationSettingInput;
};


export type MutationUpdateReasonForLeavingArgs = {
  input: UpdateReasonForLeavingInput;
};


export type MutationUpdateTypeOfAttritionArgs = {
  input: UpdateTypeOfAttritionInput;
};

export enum NotificationDate {
  Before_6Months = 'BEFORE_6_MONTHS',
  Before_14Days = 'BEFORE_14_DAYS',
  Before_30Days = 'BEFORE_30_DAYS',
  Before_45Days = 'BEFORE_45_DAYS',
  Before_90Days = 'BEFORE_90_DAYS',
  OnTheDay = 'ON_THE_DAY'
}

/** Notification option. */
export type NotificationOption = {
  enabled: Scalars['Boolean']['output'];
  notifiedUsers: Array<NotificationUser>;
};

/** Notification option (custom dates). */
export type NotificationOptionCustomDates = NotificationOption & {
  __typename?: 'NotificationOptionCustomDates';
  enabled: Scalars['Boolean']['output'];
  notifiedDates: Array<NotificationDate>;
  notifiedUsers: Array<NotificationUser>;
};

/** Notification option (static dates). */
export type NotificationOptionStaticDates = NotificationOption & {
  __typename?: 'NotificationOptionStaticDates';
  enabled: Scalars['Boolean']['output'];
  notifiedUsers: Array<NotificationUser>;
};

/** Notification setting. */
export type NotificationSetting = {
  __typename?: 'NotificationSetting';
  contractEndDate: NotificationOptionCustomDates;
  country: Country;
  countryId: Scalars['ID']['output'];
  createdAt: Scalars['DateTime']['output'];
  employmentEndDate: NotificationOptionCustomDates;
  idExpiryDate: NotificationOptionCustomDates;
  lastWorkingDate: NotificationOptionCustomDates;
  passportExpiryDate: NotificationOptionCustomDates;
  probationReviewDeadlineForEach: NotificationOptionCustomDates;
  probationReviewNeedMoreConsideration: NotificationOptionStaticDates;
  probationReviewScheduledEndDate: NotificationOptionCustomDates;
  returnFromExtendedLeave: NotificationOptionCustomDates;
  sixMonthServiceCompletion?: Maybe<NotificationOptionStaticDates>;
  updatedAt: Scalars['DateTime']['output'];
  visaExpiryDate: NotificationOptionCustomDates;
  workPermitExpiryDate: NotificationOptionCustomDates;
};

/** Output type for notification setting. */
export type NotificationSettingOutput = {
  __typename?: 'NotificationSettingOutput';
  country: Country;
  id: Scalars['ID']['output'];
};

export enum NotificationUser {
  AdminPrivate = 'ADMIN_PRIVATE',
  AdminStandard = 'ADMIN_STANDARD',
  PrimaryManager = 'PRIMARY_MANAGER',
  SuperAdmin = 'SUPER_ADMIN',
  TheEmployee = 'THE_EMPLOYEE'
}

/** Phone number. */
export type PhoneNumber = {
  __typename?: 'PhoneNumber';
  country: Country;
  countryId: Scalars['ID']['output'];
  number: Scalars['String']['output'];
};

/** Phone number. */
export type PhoneNumberInput = {
  countryId: Scalars['ID']['input'];
  number: Scalars['String']['input'];
};

/** Probation review. */
export type ProbationReview = {
  __typename?: 'ProbationReview';
  createdAt: Scalars['DateTime']['output'];
  employee: Employee;
  employeeId: Scalars['ID']['output'];
  result?: Maybe<ProbationReviewResult>;
  stages: Array<ProbationReviewStage>;
  status: ProbationReviewStatus;
  target?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

/** Output type for probation review. */
export type ProbationReviewOutput = {
  __typename?: 'ProbationReviewOutput';
  employee: Employee;
  id: Scalars['ID']['output'];
};

export enum ProbationReviewResult {
  Hold = 'HOLD',
  Passed = 'PASSED'
}

/** Probation review stage. */
export type ProbationReviewStage = {
  __typename?: 'ProbationReviewStage';
  deadline: Scalars['Date']['output'];
  deletable: Scalars['Boolean']['output'];
  reviewer?: Maybe<Employee>;
  reviewerFeedback?: Maybe<Scalars['String']['output']>;
  reviewerId?: Maybe<Scalars['ID']['output']>;
  selfFeedback?: Maybe<Scalars['String']['output']>;
};

export enum ProbationReviewStatus {
  Draft = 'DRAFT',
  Overdue = 'OVERDUE',
  Submitted = 'SUBMITTED'
}

export type Query = {
  __typename?: 'Query';
  /** Get a business unit. */
  businessUnit?: Maybe<BusinessUnit>;
  /** Get a company. */
  company?: Maybe<Company>;
  /** Get a company email domain. */
  companyEmailDomain?: Maybe<CompanyEmailDomain>;
  /** Get a country. */
  country?: Maybe<Country>;
  /** Get a currency. */
  currency?: Maybe<Currency>;
  /** Get a current employee. */
  currentEmployee: Employee;
  /** Get a department. */
  department?: Maybe<Department>;
  /** Get a division. */
  division?: Maybe<Division>;
  /** Get an employee. */
  employee?: Maybe<Employee>;
  /** Get an employee list custom filter. */
  employeeListCustomFilter?: Maybe<EmployeeListCustomFilter>;
  exportEmployees: Scalars['Url']['output'];
  /** Get a Google authorization code request URL to access Google services. */
  generateGoogleOfflineOAuthUrl: Scalars['Url']['output'];
  /** Get a Google authorization code request URL to logging into MED. */
  generateSignInOAuthUrl: Scalars['Url']['output'];
  /** Get a job. */
  job?: Maybe<Job>;
  /** List business units. */
  listBusinessUnits: ListBusinessUnits;
  /** List companies. */
  listCompanies: ListCompanies;
  /** List company email domains. */
  listCompanyEmailDomains: ListCompanyEmailDomains;
  /** List countries. */
  listCountries: ListCountries;
  /** List currencies. */
  listCurrencies: ListCurrencies;
  /** List departments. */
  listDepartments: ListDepartments;
  /** List divisions. */
  listDivisions: ListDivisions;
  /** List employee list custom filters. */
  listEmployeeListCustomFilters: ListEmployeeListCustomFilters;
  /** List employees. */
  listEmployees: ListEmployees;
  /** List Google Workspace organization unit paths. */
  listGoogleOrgUnitPaths: Array<Scalars['String']['output']>;
  /** List jobs. */
  listJobs: ListJobs;
  /** List locations. */
  listLocations: ListLocations;
  /** List off boarding - reason for leavings. */
  listReasonForLeavings: ListReasonForLeavings;
  /** List off boarding - type of attrition. */
  listTypeOfAttrition: ListTypeOfAttrition;
  /** Get a location. */
  location?: Maybe<Location>;
  /** Get a notification setting. */
  notificationSetting?: Maybe<NotificationSetting>;
  /** Get a probation review. */
  probationReview?: Maybe<ProbationReview>;
  /** List probation reviews. */
  probationReviews: ListProbationReviews;
  /** Get a off boarding - reason for leaving. */
  reasonForLeaving?: Maybe<ReasonForLeaving>;
  /** Get a report - demographic numbers. */
  reportDemographic: ReportDemographic;
  /** Get report - number of employee - all. */
  reportNumberOfEmployeeAll: ReportNumberOfEmployeeAll;
  /** Get report - number of employee - business unit. */
  reportNumberOfEmployeeBusinessUnit: ReportNumberOfEmployeeByBusinessUnit;
  /** Get report - number of employee - company. */
  reportNumberOfEmployeeCompany: ReportNumberOfEmployeeByCompany;
  /** Get report - number of employee - job. */
  reportNumberOfEmployeeJob: ReportNumberOfEmployeeByJob;
  /** Get report - number of employee - location. */
  reportNumberOfEmployeeLocation: ReportNumberOfEmployeeByLocation;
  /** Get a off boarding - type of attrition. */
  typeOfAttrition?: Maybe<TypeOfAttrition>;
};


export type QueryBusinessUnitArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCompanyArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCompanyEmailDomainArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCountryArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCurrencyArgs = {
  id: Scalars['ID']['input'];
};


export type QueryDepartmentArgs = {
  id: Scalars['ID']['input'];
};


export type QueryDivisionArgs = {
  id: Scalars['ID']['input'];
};


export type QueryEmployeeArgs = {
  id: Scalars['ID']['input'];
};


export type QueryEmployeeListCustomFilterArgs = {
  id: Scalars['ID']['input'];
};


export type QueryExportEmployeesArgs = {
  employeeIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  filter?: InputMaybe<ListEmployeesFilterInput>;
  format: EmployeeExportFormat;
};


export type QueryGenerateGoogleOfflineOAuthUrlArgs = {
  redirectUri?: InputMaybe<Scalars['Url']['input']>;
  state: Scalars['String']['input'];
};


export type QueryGenerateSignInOAuthUrlArgs = {
  redirectUri?: InputMaybe<Scalars['Url']['input']>;
  state: Scalars['String']['input'];
};


export type QueryJobArgs = {
  id: Scalars['ID']['input'];
};


export type QueryListBusinessUnitsArgs = {
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  pageNumber: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
};


export type QueryListCompaniesArgs = {
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  filterOnlyAccessible?: InputMaybe<Scalars['Boolean']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  pageNumber: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
};


export type QueryListCompanyEmailDomainsArgs = {
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  pageNumber: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
};


export type QueryListCountriesArgs = {
  hasCompanies?: InputMaybe<Scalars['Boolean']['input']>;
  hasIsdCode?: InputMaybe<Scalars['Boolean']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  pageNumber: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
};


export type QueryListCurrenciesArgs = {
  keyword?: InputMaybe<Scalars['String']['input']>;
  pageNumber: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
};


export type QueryListDepartmentsArgs = {
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  pageNumber: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
};


export type QueryListDivisionsArgs = {
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  pageNumber: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
};


export type QueryListEmployeesArgs = {
  filter?: InputMaybe<ListEmployeesFilterInput>;
  pageNumber: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
  sort?: InputMaybe<ListEmployeesSortInput>;
};


export type QueryListJobsArgs = {
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  pageNumber: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
};


export type QueryListLocationsArgs = {
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  pageNumber: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
};


export type QueryListReasonForLeavingsArgs = {
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  pageNumber: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
};


export type QueryListTypeOfAttritionArgs = {
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  pageNumber: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
};


export type QueryLocationArgs = {
  id: Scalars['ID']['input'];
};


export type QueryNotificationSettingArgs = {
  countryId: Scalars['ID']['input'];
};


export type QueryProbationReviewArgs = {
  id: Scalars['ID']['input'];
};


export type QueryProbationReviewsArgs = {
  filter?: InputMaybe<ListProbationReviewFilterInput>;
  pageNumber: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
};


export type QueryReasonForLeavingArgs = {
  id: Scalars['ID']['input'];
};


export type QueryReportDemographicArgs = {
  filter?: InputMaybe<ReportDemographicFilterInput>;
  yearMonth: Scalars['YearMonth']['input'];
};


export type QueryReportNumberOfEmployeeAllArgs = {
  contractTypes?: InputMaybe<Array<ContractTypeForReport>>;
  year: Scalars['Year']['input'];
};


export type QueryReportNumberOfEmployeeBusinessUnitArgs = {
  contractTypes?: InputMaybe<Array<ContractTypeForReport>>;
  year: Scalars['Year']['input'];
};


export type QueryReportNumberOfEmployeeCompanyArgs = {
  contractTypes?: InputMaybe<Array<ContractTypeForReport>>;
  year: Scalars['Year']['input'];
};


export type QueryReportNumberOfEmployeeJobArgs = {
  contractTypes?: InputMaybe<Array<ContractTypeForReport>>;
  year: Scalars['Year']['input'];
};


export type QueryReportNumberOfEmployeeLocationArgs = {
  contractTypes?: InputMaybe<Array<ContractTypeForReport>>;
  year: Scalars['Year']['input'];
};


export type QueryTypeOfAttritionArgs = {
  id: Scalars['ID']['input'];
};

/** Off boarding - reason for leaving. */
export type ReasonForLeaving = {
  __typename?: 'ReasonForLeaving';
  createdAt: Scalars['DateTime']['output'];
  enabled: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

/** Output type for an off boarding - reason for leaving. */
export type ReasonForLeavingOutput = {
  __typename?: 'ReasonForLeavingOutput';
  id: Scalars['ID']['output'];
  reasonForLeaving: ReasonForLeaving;
};

/** Report - demographic. */
export type ReportDemographic = {
  __typename?: 'ReportDemographic';
  age: ReportDemographicAge;
  gender: ReportDemographicGender;
  total: Scalars['Int']['output'];
  yearMonth: Scalars['YearMonth']['output'];
};

export type ReportDemographicAge = {
  __typename?: 'ReportDemographicAge';
  average?: Maybe<Scalars['BigDecimal']['output']>;
  median?: Maybe<Scalars['BigDecimal']['output']>;
  oldest?: Maybe<Scalars['Int']['output']>;
  youngest?: Maybe<Scalars['Int']['output']>;
};

/** Report - demographic filter. */
export type ReportDemographicFilterInput = {
  businessUnitIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  companyIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  contractTypes?: InputMaybe<Array<ContractTypeForReport>>;
  countryIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  departmentIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  jobIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  locationIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  nationalityIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type ReportDemographicGender = {
  __typename?: 'ReportDemographicGender';
  female: ReportDemographicGenderCount;
  male: ReportDemographicGenderCount;
  other: ReportDemographicGenderCount;
};

export type ReportDemographicGenderCount = {
  __typename?: 'ReportDemographicGenderCount';
  count: Scalars['Int']['output'];
  percentage?: Maybe<Scalars['BigDecimal']['output']>;
};

/** Report - number of employees - all. */
export type ReportNumberOfEmployeeAll = ReportNumberOfEmployeeByKey & {
  __typename?: 'ReportNumberOfEmployeeAll';
  items: Array<ReportNumberOfEmployeeAllYearMonth>;
  subtotal: ReportNumberOfEmployeeAllSubtotal;
  yearMonthList: Array<Scalars['YearMonth']['output']>;
};

/** Report - number of employees - all - subtotal. */
export type ReportNumberOfEmployeeAllSubtotal = {
  __typename?: 'ReportNumberOfEmployeeAllSubtotal';
  h1TurnOverRate?: Maybe<Scalars['BigDecimal']['output']>;
  h2TurnOverRate?: Maybe<Scalars['BigDecimal']['output']>;
  joined: Scalars['Int']['output'];
  left: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
  turnOverRate?: Maybe<Scalars['BigDecimal']['output']>;
  year: Scalars['Year']['output'];
};

/** Report - number of employees - all - by year/month. */
export type ReportNumberOfEmployeeAllYearMonth = {
  __typename?: 'ReportNumberOfEmployeeAllYearMonth';
  joined: Scalars['Int']['output'];
  left: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
  yearMonth: Scalars['YearMonth']['output'];
};

/** Report - number of employees - by business unit. */
export type ReportNumberOfEmployeeByBusinessUnit = ReportNumberOfEmployeeByKey & {
  __typename?: 'ReportNumberOfEmployeeByBusinessUnit';
  items: Array<ReportNumberOfEmployeeByBusinessUnitItem>;
  yearMonthList: Array<Scalars['YearMonth']['output']>;
};

/** Report - number of employees - by business unit item. */
export type ReportNumberOfEmployeeByBusinessUnitItem = ReportNumberOfEmployeeByKeyItem & {
  __typename?: 'ReportNumberOfEmployeeByBusinessUnitItem';
  businessUnit: BusinessUnit;
  businessUnitId: Scalars['ID']['output'];
  total: Scalars['Int']['output'];
  yearMonth: Scalars['YearMonth']['output'];
};

/** Report - number of employees - by company. */
export type ReportNumberOfEmployeeByCompany = ReportNumberOfEmployeeByKey & {
  __typename?: 'ReportNumberOfEmployeeByCompany';
  items: Array<ReportNumberOfEmployeeByCompanyItem>;
  yearMonthList: Array<Scalars['YearMonth']['output']>;
};

/** Report - number of employees - by company item. */
export type ReportNumberOfEmployeeByCompanyItem = ReportNumberOfEmployeeByKeyItem & {
  __typename?: 'ReportNumberOfEmployeeByCompanyItem';
  company: Company;
  companyId: Scalars['ID']['output'];
  total: Scalars['Int']['output'];
  yearMonth: Scalars['YearMonth']['output'];
};

/** Report - number of employees - by job. */
export type ReportNumberOfEmployeeByJob = ReportNumberOfEmployeeByKey & {
  __typename?: 'ReportNumberOfEmployeeByJob';
  items: Array<ReportNumberOfEmployeeByJobItem>;
  yearMonthList: Array<Scalars['YearMonth']['output']>;
};

/** Report - number of employees - by job item. */
export type ReportNumberOfEmployeeByJobItem = ReportNumberOfEmployeeByKeyItem & {
  __typename?: 'ReportNumberOfEmployeeByJobItem';
  job: Job;
  jobId: Scalars['ID']['output'];
  total: Scalars['Int']['output'];
  yearMonth: Scalars['YearMonth']['output'];
};

/** Report - number of employees - by key. */
export type ReportNumberOfEmployeeByKey = {
  yearMonthList: Array<Scalars['YearMonth']['output']>;
};

/** Report - number of employees - by key item. */
export type ReportNumberOfEmployeeByKeyItem = {
  total: Scalars['Int']['output'];
  yearMonth: Scalars['YearMonth']['output'];
};

/** Report - number of employees - by location. */
export type ReportNumberOfEmployeeByLocation = ReportNumberOfEmployeeByKey & {
  __typename?: 'ReportNumberOfEmployeeByLocation';
  items: Array<ReportNumberOfEmployeeByLocationItem>;
  yearMonthList: Array<Scalars['YearMonth']['output']>;
};

/** Report - number of employees - by location item. */
export type ReportNumberOfEmployeeByLocationItem = ReportNumberOfEmployeeByKeyItem & {
  __typename?: 'ReportNumberOfEmployeeByLocationItem';
  location: Location;
  locationId: Scalars['ID']['output'];
  total: Scalars['Int']['output'];
  yearMonth: Scalars['YearMonth']['output'];
};

/** Input type to send invitation emails */
export type SendInvitationEmailsInput = {
  employeeIds: Array<Scalars['ID']['input']>;
};

/** Input type to submit a probation review. */
export type SubmitProbationReviewInput = {
  employeeId: Scalars['ID']['input'];
  result: ProbationReviewResult;
  stages: Array<SubmitProbationReviewStageInput>;
  target: Scalars['String']['input'];
};

/** Input type to submit a probation review stage. */
export type SubmitProbationReviewStageInput = {
  deadline: Scalars['Date']['input'];
  reviewerFeedback: Scalars['String']['input'];
  reviewerId: Scalars['ID']['input'];
};

/** Off boarding - type of attrition. */
export type TypeOfAttrition = {
  __typename?: 'TypeOfAttrition';
  createdAt: Scalars['DateTime']['output'];
  enabled: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

/** Output type for an off boarding - type of attrition. */
export type TypeOfAttritionOutput = {
  __typename?: 'TypeOfAttritionOutput';
  id: Scalars['ID']['output'];
  typeOfAttrition: TypeOfAttrition;
};

/** Input type to update a business unit. */
export type UpdateBusinessUnitInput = {
  divisionId: Scalars['ID']['input'];
  enabled: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

/** Input type to update a company domain email. */
export type UpdateCompanyEmailDomainInput = {
  disableExternalAccount: Scalars['Boolean']['input'];
  enabled: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
};

/** Input type to update a company. */
export type UpdateCompanyInput = {
  countryId: Scalars['ID']['input'];
  enabled: Scalars['Boolean']['input'];
  googleOrgUnitPath: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  probationReviewTerm: CompanyProbationReviewTermInput;
  probationReviewTimes: Scalars['Int']['input'];
};

/** Input type to update a department. */
export type UpdateDepartmentInput = {
  enabled: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

/** Input type to update a division. */
export type UpdateDivisionInput = {
  enabled: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

/** Input type to update an employee's personal information - company asset. */
export type UpdateEmployeeCompanyAssetInput = {
  assignedDate: Scalars['Date']['input'];
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  number: Scalars['String']['input'];
  returnedDate?: InputMaybe<Scalars['Date']['input']>;
  type: EmployeeCompanyAssetType;
};

/** Input type to update an employee contract */
export type UpdateEmployeeContractInput = {
  companyId: Scalars['ID']['input'];
  contractType: ContractType;
  effectiveDate: Scalars['Date']['input'];
  effectiveDateUntil?: InputMaybe<Scalars['Date']['input']>;
  employeeCode?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  isMain: Scalars['Boolean']['input'];
  onSecondment: Scalars['Boolean']['input'];
};

/** Input type to update an employee contract note */
export type UpdateEmployeeContractNoteInput = {
  employeeId: Scalars['ID']['input'];
  note: Scalars['String']['input'];
};

/** Input type to update an employee's personal information - dependent. */
export type UpdateEmployeeDependentInput = {
  dateOfBirth: Scalars['Date']['input'];
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  relation: EmployeeRelationType;
};

/** Input type to update an employee's personal information - emergency contact. */
export type UpdateEmployeeEmergencyContactInput = {
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  phoneNumber: PhoneNumberInput;
  relation: EmployeeRelationType;
  remarks?: InputMaybe<Scalars['String']['input']>;
};

/** Input type to update an employee - general information. */
export type UpdateEmployeeGeneralInformationInput = {
  companyEmail: EmployeeCompanyEmailInput;
  dottedLineManagerId?: InputMaybe<Scalars['ID']['input']>;
  employeeId: Scalars['ID']['input'];
  greenhouseLink?: InputMaybe<Scalars['Url']['input']>;
  hiredDate: Scalars['Date']['input'];
  hiringSource?: InputMaybe<EmployeeHiringSource>;
  isNewGraduate: Scalars['Boolean']['input'];
  legalFullName: Scalars['String']['input'];
  nameInLocalLanguage?: InputMaybe<Scalars['String']['input']>;
  nationalityId: Scalars['ID']['input'];
  needsProbationReview: Scalars['Boolean']['input'];
  preferredFullName: Scalars['String']['input'];
  primaryManagerId: Scalars['ID']['input'];
};

/** Input type to update an employee job history */
export type UpdateEmployeeJobHistoryInput = {
  businessUnitId: Scalars['ID']['input'];
  departmentId: Scalars['ID']['input'];
  effectiveDate: Scalars['Date']['input'];
  effectiveDateUntil?: InputMaybe<Scalars['Date']['input']>;
  id: Scalars['ID']['input'];
  jobId: Scalars['ID']['input'];
  jobTitle: Scalars['String']['input'];
  level: JobHistoryLevel;
  locationId: Scalars['ID']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  regionalRole: Scalars['Boolean']['input'];
  team?: InputMaybe<Scalars['String']['input']>;
  type: JobHistoryType;
};

/** Input type to update an employee job history note */
export type UpdateEmployeeJobHistoryNoteInput = {
  employeeId: Scalars['ID']['input'];
  note: Scalars['String']['input'];
};

/** Input type to update an employee list custom filter. */
export type UpdateEmployeeListCustomFilterInput = {
  businessUnitIds: Array<Scalars['ID']['input']>;
  companyIds: Array<Scalars['ID']['input']>;
  contractTypes: Array<ContractType>;
  departmentIds: Array<Scalars['ID']['input']>;
  divisionIds: Array<Scalars['ID']['input']>;
  employmentEndDate?: InputMaybe<LocalDateRangeInput>;
  externalAccountGoogle: Array<EmployeeExternalAccountStatus>;
  externalAccountGreenhouse: Array<EmployeeExternalAccountStatus>;
  externalAccountHubspot: Array<EmployeeExternalAccountStatus>;
  externalAccountSlack: Array<EmployeeExternalAccountStatus>;
  hiredDate?: InputMaybe<LocalDateRangeInput>;
  id: Scalars['ID']['input'];
  isLeave: Scalars['Boolean']['input'];
  isOnSecondment: Scalars['Boolean']['input'];
  isOpenFilter: Scalars['Boolean']['input'];
  jobIds: Array<Scalars['ID']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  levels: Array<JobHistoryLevel>;
  locationIds: Array<Scalars['ID']['input']>;
  managerIds: Array<Scalars['ID']['input']>;
  managerIncludesAllConnections: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  nationalityIds: Array<Scalars['ID']['input']>;
  roles: Array<EmployeeRole>;
  statuses: Array<EmployeeStatus>;
};

/** Input type to update an employee's off boarding - do not deactivate. */
export type UpdateEmployeeOffBoardingDoNotDeactivateInput = {
  google: Scalars['Boolean']['input'];
  greenhouse: Scalars['Boolean']['input'];
};

/** Input type to update an employee's off boarding. */
export type UpdateEmployeeOffBoardingInput = {
  detailReasonForLeaving?: InputMaybe<Scalars['String']['input']>;
  doNotDeactivate: UpdateEmployeeOffBoardingDoNotDeactivateInput;
  employeeId: Scalars['ID']['input'];
  employmentEndDate?: InputMaybe<Scalars['Date']['input']>;
  lastWorkingDate?: InputMaybe<Scalars['Date']['input']>;
  reasonForLeavingId?: InputMaybe<Scalars['ID']['input']>;
  typeOfAttritionId?: InputMaybe<Scalars['ID']['input']>;
};

/** Input type to update an employee's permission. */
export type UpdateEmployeePermissionInput = {
  companyIds: Array<Scalars['ID']['input']>;
  employeeId: Scalars['ID']['input'];
  employeeIds: Array<Scalars['ID']['input']>;
  role: EmployeeRole;
};

/** Input type to update an employee - personal information. */
export type UpdateEmployeePersonalInformationInput = {
  bank?: InputMaybe<EmployeeBankInput>;
  consultingFeeBank?: InputMaybe<EmployeeConsultingFeeBankInput>;
  currentAddress?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth: Scalars['Date']['input'];
  employeeId: Scalars['ID']['input'];
  gender: EmployeeGender;
  idExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  idIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  idNumber?: InputMaybe<Scalars['String']['input']>;
  idType?: InputMaybe<EmployeeIdType>;
  maritalStatus?: InputMaybe<EmployeeMaritalStatus>;
  nhiDependents?: InputMaybe<Scalars['Int']['input']>;
  passportExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  passportIssuedPlace?: InputMaybe<Scalars['String']['input']>;
  passportName?: InputMaybe<Scalars['String']['input']>;
  passportNumber?: InputMaybe<Scalars['String']['input']>;
  passportPlaceOfBirth?: InputMaybe<Scalars['String']['input']>;
  pensionVoluntaryContributionRate?: InputMaybe<Scalars['String']['input']>;
  permanentAddress?: InputMaybe<Scalars['String']['input']>;
  personalEmail?: InputMaybe<Scalars['String']['input']>;
  personalPhoneNumber?: InputMaybe<PhoneNumberInput>;
  residentialCountryId: Scalars['ID']['input'];
  socialInsuranceNotes?: InputMaybe<Scalars['String']['input']>;
  socialInsuranceNumber?: InputMaybe<Scalars['String']['input']>;
  taxCode?: InputMaybe<Scalars['String']['input']>;
  taxWithholdingRate?: InputMaybe<Scalars['String']['input']>;
};

/** Input type to update an employee's personal information - visa. */
export type UpdateEmployeeVisaInput = {
  id: Scalars['ID']['input'];
  visaExpiryDate: Scalars['Date']['input'];
  visaIssuedDate: Scalars['Date']['input'];
  visaIssuedPlace: Scalars['String']['input'];
  visaNumber: Scalars['String']['input'];
  visaType: Scalars['String']['input'];
  workPermitExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitIssuedDate?: InputMaybe<Scalars['Date']['input']>;
  workPermitNumber?: InputMaybe<Scalars['String']['input']>;
};

/** Input type to update a job. */
export type UpdateJobInput = {
  enabled: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

/** Input type to update a location. */
export type UpdateLocationInput = {
  countryId?: InputMaybe<Scalars['ID']['input']>;
  enabled: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

/** Input type to update notification option (custom dates). */
export type UpdateNotificationOptionCustomDatesInput = {
  enabled: Scalars['Boolean']['input'];
  notifiedDates: Array<NotificationDate>;
  notifiedUsers: Array<NotificationUser>;
};

/** Input type to update notification option (static dates). */
export type UpdateNotificationOptionStaticDatesInput = {
  enabled: Scalars['Boolean']['input'];
  notifiedUsers: Array<NotificationUser>;
};

/** Input type to update a notification setting. */
export type UpdateNotificationSettingInput = {
  contractEndDate: UpdateNotificationOptionCustomDatesInput;
  countryId: Scalars['ID']['input'];
  employmentEndDate: UpdateNotificationOptionCustomDatesInput;
  idExpiryDate: UpdateNotificationOptionCustomDatesInput;
  lastWorkingDate: UpdateNotificationOptionCustomDatesInput;
  passportExpiryDate: UpdateNotificationOptionCustomDatesInput;
  probationReviewDeadlineForEach: UpdateNotificationOptionCustomDatesInput;
  probationReviewNeedMoreConsideration: UpdateNotificationOptionStaticDatesInput;
  probationReviewScheduledEndDate: UpdateNotificationOptionCustomDatesInput;
  returnFromExtendedLeave: UpdateNotificationOptionCustomDatesInput;
  /** only for Japan [countryId = "JP"] */
  sixMonthServiceCompletion?: InputMaybe<UpdateNotificationOptionStaticDatesInput>;
  visaExpiryDate: UpdateNotificationOptionCustomDatesInput;
  workPermitExpiryDate: UpdateNotificationOptionCustomDatesInput;
};

/** Input type to update an off boarding - reason for leaving. */
export type UpdateReasonForLeavingInput = {
  enabled: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

/** Input type to update an off boarding - type of attrition. */
export type UpdateTypeOfAttritionInput = {
  enabled: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};
