import { Card, Spinner, useDiscardChangesModal } from '@/shared/components';
import { useEmployeeAccess, useFilters } from '@/shared/hooks';
import {
  EmployeeEditTab,
  EmployeeEditTabFilterType,
  EmployeeEditTabLabels,
  EmployeeTabParser,
} from '@/shared/types';
import { Tabs, TabsValue } from '@any-ui-react/core';
import { Suspense, lazy, useCallback, useState } from 'react';

const GeneralInformation = lazy(() =>
  import('../general-information/GeneralInformation').then((module) => ({
    default: module.GeneralInformation,
  }))
);
const PersonalInformation = lazy(() =>
  import('../personal-information/PersonalInformation').then((module) => ({
    default: module.PersonalInformation,
  }))
);
const OffBoarding = lazy(() =>
  import('../off-boarding/OffBoarding').then((module) => ({
    default: module.OffBoarding,
  }))
);
const JobContractHistory = lazy(() =>
  import('../job-contract-history/JobContractHistory').then((module) => ({
    default: module.JobContractHistory,
  }))
);
const ExternalAccounts = lazy(() =>
  import('../external-accounts/ExternalAccounts').then((module) => ({
    default: module.ExternalAccounts,
  }))
);
const Permission = lazy(() =>
  import('../permission/Permission').then((module) => ({
    default: module.Permission,
  }))
);
const ProbationReview = lazy(() =>
  import('../probation-review').then((module) => ({
    default: module.ProbationReview,
  }))
);
const PerformanceReview = lazy(() =>
  import('../performance-review').then((module) => ({
    default: module.PerformanceReview,
  }))
);

export const EmployeeEditTabs = () => {
  const {
    hasPersonalInformationViewAccess,
    hasOffBoardingViewAccess,
    hasJobContractHistoryViewAccess,
    hasExternalAccountsViewAccess,
    hasPermissionViewAccess,
    hasProbationReviewViewAccess,
  } = useEmployeeAccess();

  const { openDiscardChangesModal } = useDiscardChangesModal();

  const TabComponents = {
    [EmployeeEditTab.GENERAL_INFORMATION]: GeneralInformation,
    [EmployeeEditTab.PERSONAL_INFORMATION]: PersonalInformation,
    [EmployeeEditTab.OFF_BOARDING]: OffBoarding,
    [EmployeeEditTab.JOB_HISTORY]: JobContractHistory,
    [EmployeeEditTab.EXTERNAL_ACCOUNTS]: ExternalAccounts,
    [EmployeeEditTab.PERMISSION]: Permission,
    [EmployeeEditTab.PROBATION_REVIEW]: ProbationReview,
    [EmployeeEditTab.PERFORMANCE_REVIEW]: PerformanceReview,
  };

  const filters = useFilters<EmployeeEditTabFilterType>(
    {
      tab: EmployeeEditTab.GENERAL_INFORMATION,
    },
    {
      parser: EmployeeTabParser.parse,
      withLocalStorage: false,
    }
  );

  const [isDirtyTab, setIsDirtyTab] = useState(false);

  const tabs = [
    EmployeeEditTab.GENERAL_INFORMATION,
    ...(hasPermissionViewAccess ? [EmployeeEditTab.PERMISSION] : []),
    ...(hasPersonalInformationViewAccess
      ? [EmployeeEditTab.PERSONAL_INFORMATION]
      : []),
    ...(hasJobContractHistoryViewAccess ? [EmployeeEditTab.JOB_HISTORY] : []),
    ...(hasOffBoardingViewAccess ? [EmployeeEditTab.OFF_BOARDING] : []),
    EmployeeEditTab.PERFORMANCE_REVIEW,
    ...(hasProbationReviewViewAccess ? [EmployeeEditTab.PROBATION_REVIEW] : []),
    ...(hasExternalAccountsViewAccess
      ? [EmployeeEditTab.EXTERNAL_ACCOUNTS]
      : []),
  ].map((value) => ({
    tab: value,
    value: value,
  }));

  const onTabChange = useCallback(
    (value: TabsValue) => {
      if (isDirtyTab) {
        openDiscardChangesModal({
          onSuccess: () => {
            filters.changeFilter('tab', value);
          },
        });
      } else {
        filters.changeFilter('tab', value);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [openDiscardChangesModal]
  );

  return (
    <Tabs
      value={filters.current.tab}
      dir='ltr'
      className={'tab-wrapper'}
      orientation='horizontal'
      keepMounted={false}
      onTabChange={onTabChange}
    >
      {tabs.length > 1 && (
        <Card withPadding={false}>
          <Tabs.List className='flex flex-nowrap overflow-y-hidden overflow-x-scroll scrollbar-thin scrollbar-track-transparent scrollbar-thumb-gray-2'>
            {tabs.map((tab) => (
              <Tabs.Tab key={tab.value} value={tab.value}>
                {EmployeeEditTabLabels[tab.tab]}
              </Tabs.Tab>
            ))}
          </Tabs.List>
        </Card>
      )}

      {tabs.map((tab) => {
        const Component = TabComponents[tab.value];
        return (
          <Tabs.Panel key={tab.value} value={tab.value}>
            <div>
              <Suspense
                fallback={
                  <div className='flex w-full items-center justify-center py-11'>
                    <Spinner />
                  </div>
                }
              >
                <Component
                  onFormStateChange={(isDirty) => setIsDirtyTab(isDirty)}
                />
              </Suspense>
            </div>
          </Tabs.Panel>
        );
      })}
    </Tabs>
  );
};
