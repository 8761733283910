import { ReportDemographic } from '@/shared/graphql';
import { Spinner } from 'src/components/shared';
import { NumberUtils } from 'src/utils/number.utils';
import { DemographicData } from './DemographicData';

export const DemographicReportDetails = ({
  data,
  loading,
}: {
  data?: ReportDemographic;
  loading: boolean;
}) => {
  if (loading) {
    return (
      <div className='py-10'>
        <Spinner />
      </div>
    );
  }

  if (!data) {
    return <div className='flex w-full justify-center py-10'>No data</div>;
  }

  return (
    <div className='flex flex-col divide-y px-2'>
      <div className='py-10'>
        <span className='text-sm font-semibold'>Total</span>
        <div className='mt-6 flex items-end space-x-1 border-l-2 px-2'>
          <span className='text-[40px] font-semibold'>{data.total}</span>
          <span className='py-2.5'>people</span>
        </div>
      </div>

      <div className='flex flex-col space-y-6 py-10'>
        <span className='text-sm font-semibold'>Age</span>
        <div className='flex w-11/12 justify-between'>
          <DemographicData
            label='Average'
            value={data.age.average}
            suffix='years old'
          />

          <DemographicData
            label='Median'
            value={data.age.median}
            suffix='years old'
          />

          <DemographicData
            label='Youngest'
            value={data.age.youngest}
            suffix='years old'
          />

          <DemographicData
            label='Oldest'
            value={data.age.oldest}
            suffix='years old'
          />
        </div>
      </div>

      <div className='flex flex-col space-y-6 py-10'>
        <span className='text-sm font-semibold'>Gender</span>
        <div className='flex flex-col space-y-10'>
          <div className='flex w-11/12'>
            <DemographicData
              label='Male Number'
              value={String(data.gender.male.count)}
            />

            <DemographicData
              label='Male Percentage'
              value={NumberUtils.formatDecimalToPercent(
                data.gender.male.percentage
              )}
            />

            <DemographicData
              label='Female Number'
              value={String(data.gender.female.count)}
            />

            <DemographicData
              label='Female Percentage'
              value={NumberUtils.formatDecimalToPercent(
                data.gender.female.percentage
              )}
            />
          </div>
          <div className='flex w-11/12'>
            <DemographicData
              label='Others Number'
              value={String(data.gender.other.count)}
            />

            <DemographicData
              label='Others Percentage'
              value={NumberUtils.formatDecimalToPercent(
                data.gender.other.percentage
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
