import {
  ContractText,
  EMPTY_DEFAULT,
  NoResult,
  Table,
  TableLayout,
} from '@/shared/components';
import { TableSelection } from '@/shared/types';
import { DateUtils, StringUtils } from '@/shared/utils';
import { Badge } from '@any-ui-react/core';
import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  PerformanceReviewListItemType,
  PerformanceReviewStatus,
} from '../../../types';

interface Props {
  data?: PerformanceReviewListItemType[];
  loading: boolean;
  page: number;
}

export const PerformanceReviewListTable = ({ data, loading, page }: Props) => {
  const [selection, setSelection] = useState<
    TableSelection<PerformanceReviewListItemType>
  >({
    items: [],
    allPages: false,
  });

  const layout: TableLayout<PerformanceReviewListItemType>[] = useMemo(
    () => [
      {
        headerRender: () => 'Preferred Full Name',
        accessor: 'preferredFullName',
        truncated: true,
        sticky: true,
        cellRender: ({ employeeId, employeeName }) => {
          return (
            <Link to={`/performance-review/edit/${employeeId}`}>
              <span className='text-blue-6 hover:cursor-pointer'>
                {employeeName}
              </span>
            </Link>
          );
        },
      },
      {
        headerRender: () => 'Performance Review Status',
        accessor: 'performanceReviewStatus',
        cellRender: ({ performanceReviewStatus }) => {
          switch (performanceReviewStatus) {
            case PerformanceReviewStatus.TARGET_SETTING:
              return <Badge color='yellow'>Target Setting</Badge>;
            case PerformanceReviewStatus.TARGET_EVALUATION:
              return <Badge color='blue'>Target Evaluation</Badge>;
            case PerformanceReviewStatus.CLOSED_FOR_EDITS:
              return <Badge color='green'>Closed for Edits</Badge>;
          }
        },
      },
      {
        headerRender: () => 'Contract Type',
        accessor: 'contractType',
        cellRender: ({ contractType }) => {
          return (
            <span>
              {contractType
                ? String(ContractText[contractType])
                : EMPTY_DEFAULT}
            </span>
          );
        },
      },
      {
        headerRender: () => 'Total target weight',
        accessor: 'totalTargetWeight',
        cellRender: ({ totalTargetWeight }) => {
          return <span>{totalTargetWeight}</span>;
        },
      },
      {
        headerRender: () => 'Probation End Date',
        accessor: 'probationEndDate',
        cellRender: ({ probationEndDate }) =>
          DateUtils.formatDate(probationEndDate, 'MMM dd, yyyy'),
      },
      {
        headerRender: () => 'Level',
        accessor: 'level',
        cellRender: ({ level }) => StringUtils.withFallback(level),
      },
      {
        headerRender: () => 'Company',
        accessor: 'company',
        cellRender: ({ companyName }) => StringUtils.withFallback(companyName),
      },
      {
        headerRender: () => 'Office Location',
        accessor: 'officeLocation',
        cellRender: ({ officeLocation }) =>
          StringUtils.withFallback(officeLocation),
      },
      {
        headerRender: () => 'Business Unit',
        accessor: 'businessUnit',
        cellRender: ({ businessUnit }) =>
          StringUtils.withFallback(businessUnit),
      },
      {
        headerRender: () => 'Department',
        accessor: 'department',
        cellRender: ({ department }) => StringUtils.withFallback(department),
      },
      {
        headerRender: () => 'Job Title',
        accessor: 'jobTitle',
        cellRender: ({ jobTitle }) => StringUtils.withFallback(jobTitle),
      },
    ],
    []
  );

  return (
    <Table<PerformanceReviewListItemType>
      page={page}
      layout={layout}
      loading={loading}
      data={data || []}
      notFoundTemplate={
        <NoResult
          title='No Data Found'
          description='Try changing the filters or search terms'
        />
      }
      allowFloatingHeader
      selection={selection}
      onSelectionChange={setSelection}
      selectable={{
        byField: 'employeeId',
        total: data?.length || 0,
        // aside: (
        //   <Button
        //     className='bg-blue-6'
        //     loading={resendLoading}
        //
        //   >
        //     Action here
        //   </Button>
        // ),
      }}
    />
  );
};
