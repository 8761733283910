import {
  BusinessUnitLazySelect,
  CompanyLazySelect,
  ContractText,
  ContractTypeSelect,
  DEFAULT_PAGE_SIZE,
  DepartmentLazySelect,
  InputSearch,
  JobLevelSelect,
  LocationLazySelect,
  QuarterSelect,
  YearPicker,
} from '@/shared/components';
import { FilterProps } from '@/shared/hooks';
import { StringUtils } from '@/shared/utils';
import { Checkbox } from '@any-ui-react/core';
import { Select, useMultiSelect } from '@any-ui-react/select';
import { PerformanceReviewStatus } from 'src/pages/performance-review/types';
import { PerformanceReviewListFilterType } from './PerformanceReviewList.filters';

export const PerformanceReviewListFilter = ({
  filters,
}: {
  filters: FilterProps<PerformanceReviewListFilterType>;
}) => {
  const reviewStatusOptions = Object.values(PerformanceReviewStatus).map(
    (value) => ({
      label: StringUtils.capitalizeAll(value, '_'),
      value: value,
    })
  );
  const multiSelectReviewStatusProps = useMultiSelect({
    getSelectedLabel: (count) => `Review Status (${count})`,
    options: reviewStatusOptions,
  });

  const { changeFilter, removeFilter, current } = filters;

  return (
    <div className='grid grid-cols-8 gap-4 p-4'>
      <div className='col-span-8'>
        <div className='grid grid-cols-4 gap-4'>
          <YearPicker
            value={current.year}
            onChange={(value) => changeFilter('year', value)}
          />
          <QuarterSelect
            value={current.quarter}
            onChange={(value) => changeFilter('quarter', value)}
          />
        </div>
      </div>

      <InputSearch
        className='col-span-2'
        onClear={() => removeFilter('keyword')}
        onSearch={(text: string) => changeFilter('keyword', text)}
        placeholder='Keyword Search'
        value={current.keyword}
      />

      <Select
        value={current.performanceReviewStatuses?.map((item) => ({
          label: StringUtils.capitalizeAll(item, '_'),
          value: item,
        }))}
        placeholder='Performance Review Status'
        onChange={(newValue) =>
          changeFilter(
            'performanceReviewStatuses',
            newValue.map(({ value }) => value)
          )
        }
        {...multiSelectReviewStatusProps}
        wrapper={{
          className: 'col-span-2',
        }}
      />
      <div className='col-span-2'>
        <ContractTypeSelect
          isMulti={true}
          onChange={(newValue) =>
            changeFilter(
              'contractTypes',
              newValue.map(({ value }) => value)
            )
          }
          value={current.contractTypes?.map((item) => ({
            label: String(ContractText[item]),
            value: item,
          }))}
        />
      </div>
      <div className='col-span-2'>
        <CompanyLazySelect
          isMulti={true}
          isSearchable={true}
          isClearable={true}
          value={current.companyIds}
          valueFormatter={(item) => item.id}
          defaultQueryOptions={{
            variables: {
              pageNumber: 1,
              pageSize: DEFAULT_PAGE_SIZE,
              keyword: null,
              filterOnlyAccessible: false,
            },
          }}
          placeholder={'Companies'}
          onChange={(newValue) => {
            changeFilter(
              'companyIds',
              newValue.map(({ value }) => value)
            );
          }}
        />
      </div>

      <div className='col-span-2'>
        <LocationLazySelect
          isMulti={true}
          isSearchable={true}
          isClearable={true}
          value={current.locationIds}
          defaultQueryOptions={{
            variables: {
              pageNumber: 1,
              pageSize: DEFAULT_PAGE_SIZE,
              enabled: null,
              keyword: null,
            },
          }}
          placeholder='Office Location'
          onChange={(option) => {
            changeFilter(
              'locationIds',
              option.map(({ value }) => value)
            );
          }}
        />
      </div>
      <div className='col-span-2'>
        <BusinessUnitLazySelect
          isMulti={true}
          isSearchable={true}
          isClearable={true}
          value={current.businessUnitIds}
          onChange={(newValue) => {
            changeFilter(
              'businessUnitIds',
              newValue.map(({ value }) => value)
            );
          }}
          defaultQueryOptions={{
            variables: {
              pageNumber: 1,
              pageSize: DEFAULT_PAGE_SIZE,
              keyword: null,
              enabled: null,
            },
          }}
          placeholder={'Business Unit'}
        />
      </div>
      <div className='col-span-2'>
        <DepartmentLazySelect
          isMulti={true}
          isSearchable={true}
          isClearable={true}
          value={current.departmentIds}
          valueFormatter={(item) => item.id}
          onChange={(newValue) => {
            changeFilter(
              'departmentIds',
              newValue.map(({ value }) => value)
            );
          }}
          defaultQueryOptions={{
            variables: {
              pageNumber: 1,
              pageSize: DEFAULT_PAGE_SIZE,
              enabled: null,
            },
          }}
          placeholder={'Department'}
        />
      </div>

      <div className='col-span-2'>
        <JobLevelSelect
          isMulti={true}
          onChange={(newValue) =>
            changeFilter(
              'levels',
              newValue.map(({ value }) => value)
            )
          }
          value={current.levels?.map((item) => ({
            label: item.toString(),
            value: item,
          }))}
        />
      </div>

      <Checkbox
        className='col-span-2 '
        checked={current.showDirectReports}
        label='Show direct reports only'
        onChange={(e) => {
          changeFilter('showDirectReports', e.currentTarget.checked);
        }}
      />
    </div>
  );
};
