import { ProbationReviewDetailType } from '@/shared/types';
import { create, each, enforce, test } from 'vest';

export const reviewerFormValidationSuite = create(
  ({ stages, target, result }: ProbationReviewDetailType) => {
    test('result', 'Final result is required', () => {
      enforce(result).isNotEmpty();
    });

    test('target', 'Target is required', () => {
      enforce(target).isNotEmpty();
    });

    each(stages, (stage, index) => {
      test(`stages.${index}.reviewerId`, "Reviewer's is required", () => {
        enforce(stage.reviewerId).isNotBlank();
      });

      test(`stages.${index}.deadline`, 'Deadline is required', () => {
        enforce(stage.deadline).isNotBlank();
      });

      test(
        `stages.${index}.reviewerFeedback`,
        `Reviewer's feedback is required`,
        () => {
          enforce(stage.reviewerFeedback).isNotBlank();
        }
      );
    });
  }
);
