import { CrossCircleFill, Input, Popover } from '@any-ui-react/core';
import { DatePicker as MantimeDatePicker } from '@mantine/dates';
import { format, isValid } from 'date-fns';
import { useEffect, useState } from 'react';
import { ChevronDownIcon } from '../icons';

interface Props {
  value?: string;
  onChange: (value?: string) => void;
  minDate?: Date;
  maxDate?: Date;
  label?: string;
  required?: boolean;
  error?: string;
  placeholder?: string;
  dateFormat?: string;
  disabled?: boolean;
  withinPortal?: boolean;
  clearable?: boolean;
}

export const DatePicker = ({
  value,
  onChange,
  minDate,
  maxDate,
  label,
  required,
  error,
  placeholder,
  dateFormat = 'yyyy-MM-dd',
  disabled,
  withinPortal = true,
  clearable,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [dateInput, setDateInput] = useState(value);

  useEffect(() => {
    setDateInput(value);
  }, [value]);

  return (
    <Popover
      position='bottom-start'
      closeOnClickOutside
      offset={0}
      opened={isOpen}
      onChange={setIsOpen}
      withinPortal={withinPortal}
    >
      <Popover.Target>
        <Input.Wrapper onClick={() => setIsOpen((prev) => !prev)}>
          {label && <Input.Label required={required}>{label}</Input.Label>}
          <div className='relative'>
            <Input
              placeholder={placeholder}
              onClick={(prev) => setIsOpen(!prev)}
              value={dateInput || ''}
              disabled={disabled}
              onChange={(e) => {
                setDateInput(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && value && isValid(new Date(value))) {
                  onChange(dateInput);
                  setIsOpen(false);
                }
              }}
              error={error}
            />

            {clearable && dateInput ? (
              <CrossCircleFill
                className='absolute right-4 top-2 z-10 cursor-pointer'
                height={20}
                width={20}
                fill='#C5D0DA'
                onClick={(event) => {
                  event.stopPropagation();
                  setDateInput(undefined);
                  onChange(undefined);
                }}
              />
            ) : (
              <ChevronDownIcon className='absolute right-4 top-2' />
            )}
          </div>
          {error && <Input.Error className='mt-1'>{error}</Input.Error>}
        </Input.Wrapper>
      </Popover.Target>

      <Popover.Dropdown className='absolute max-h-[26rem] rounded border border-gray-2 bg-white shadow-sm'>
        <MantimeDatePicker
          className='bg-white'
          value={dateInput ? new Date(dateInput) : undefined}
          defaultDate={dateInput ? new Date(dateInput) : undefined}
          minDate={minDate}
          maxDate={maxDate}
          onChange={(v) => {
            if (v) {
              setDateInput(format(v, dateFormat));
              onChange(format(v, dateFormat));
              setIsOpen(false);
            }
          }}
        />
      </Popover.Dropdown>
    </Popover>
  );
};
