import { ContractType, JobHistoryLevel } from '@/shared/graphql';
import {
  PerformanceReviewListItemType,
  PerformanceReviewStatus,
} from '../types';

// create mock data for performance review list with type PerformanceReviewListItem[]
export const performanceReviewListMockData: PerformanceReviewListItemType[] = [
  {
    employeeId: '1',
    employeeName: 'John Doe',
    contractType: ContractType.Contractor,
    performanceReviewStatus: PerformanceReviewStatus.TARGET_SETTING,
    totalTargetWeight: 0,
    probationEndDate: '2021-12-31',
    level: JobHistoryLevel.L0,
    companyName: 'Company A',
    officeLocation: 'Location A',
    businessUnit: 'Business Unit A',
    department: 'Department A',
    jobTitle: 'Job Title A',
  },
  {
    employeeId: '2',
    employeeName: 'Jane Doe',
    contractType: ContractType.Intern,
    performanceReviewStatus: PerformanceReviewStatus.TARGET_EVALUATION,
    totalTargetWeight: 0,
    probationEndDate: '2021-12-31',
    level: JobHistoryLevel.L1,
    companyName: 'Company B',
    officeLocation: 'Location B',
    businessUnit: 'Business Unit B',
    department: 'Department B',
    jobTitle: 'Job Title B',
  },
  {
    employeeId: '3',
    employeeName: 'John Smith',
    contractType: ContractType.FtFixedTerm,
    performanceReviewStatus: PerformanceReviewStatus.CLOSED_FOR_EDITS,
    totalTargetWeight: 0,
    probationEndDate: '2021-12-31',
    level: JobHistoryLevel.L2,
    companyName: 'Company C',
    officeLocation: 'Location C',
    businessUnit: 'Business Unit C',
    department: 'Department C',
    jobTitle: 'Job Title C',
  },
];
