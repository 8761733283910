import {
  ContractText,
  EMPTY_DEFAULT,
  NoResult,
  Table,
  TableLayout,
} from '@/shared/components';
import {
  EmployeeExternalAccountStatus,
  EmployeeExternalAccountType,
  EmployeeStatus,
} from '@/shared/graphql';
import { useResendEmailInvitation } from '@/shared/hooks';
import { TableSelection } from '@/shared/types';
import { DateUtils, StringUtils } from '@/shared/utils';
import { Badge, Button } from '@any-ui-react/core';
import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { EmployeeList, EmployeeListItem } from '../../types';

interface UserTableProps {
  employees?: EmployeeList;
  loading: boolean;
  page: number;
}

export const EmployeeListTable = ({
  employees,
  loading,
  page,
}: UserTableProps) => {
  const { resendEmail, resendLoading } = useResendEmailInvitation();
  const [selection, setSelection] = useState<TableSelection<EmployeeListItem>>({
    items: [],
    allPages: false,
  });

  const layout: TableLayout<EmployeeListItem>[] = useMemo(
    () => [
      {
        headerRender: () => 'Preferred Full Name',
        tdClassName: '!max-w-[182px] !min-w-[182px]',
        thClassName: '!max-w-[182px] !min-w-[182px]',
        accessor: 'preferredFullName',
        truncated: true,
        sticky: true,
        cellRender: (employee) => {
          return (
            <Link to={`/employees/edit/${employee.id}`}>
              <span className='text-blue-6 hover:cursor-pointer'>
                {employee.generalInformation.preferredFullName}
              </span>
            </Link>
          );
        },
      },
      {
        headerRender: () => 'ID',
        accessor: 'employeeId',
        truncated: true,
        sticky: true,
        cellRender: ({ id }) => {
          return <span>{id}</span>;
        },
      },
      {
        headerRender: () => 'Status',
        accessor: 'status',
        cellRender: ({ status }) => {
          const getStatusColor = (status: EmployeeStatus) => {
            switch (status) {
              case EmployeeStatus.Active:
                return 'blue';
              case EmployeeStatus.Inactive:
                return 'red';
              case EmployeeStatus.PreOnboarding:
                return 'gray';
              default:
                return 'gray';
            }
          };
          return (
            <Badge className='capitalize' color={getStatusColor(status)}>
              {StringUtils.capitalizeAll(status, '_')}
            </Badge>
          );
        },
      },

      {
        headerRender: () => 'Legal Full Name',
        accessor: 'legalFullName',
        cellRender: ({ generalInformation }) => {
          return <span>{generalInformation.legalFullName}</span>;
        },
      },
      {
        headerRender: () => 'Email',
        accessor: 'email',
        cellRender: ({ generalInformation }) => {
          return (
            <span>
              {generalInformation.companyEmail.fullAddress || EMPTY_DEFAULT}
            </span>
          );
        },
      },
      {
        headerRender: () => 'Hired Date',
        accessor: 'hiredDate',
        cellRender: ({ generalInformation }) => {
          return (
            <span>
              {generalInformation.hiredDate
                ? DateUtils.formatDate(generalInformation.hiredDate)
                : EMPTY_DEFAULT}
            </span>
          );
        },
      },
      {
        headerRender: () => 'Contract Type',
        accessor: 'contractType',
        cellRender: ({ currentContract }) => {
          return (
            <span>
              {currentContract?.contractType
                ? String(ContractText[currentContract?.contractType])
                : EMPTY_DEFAULT}
            </span>
          );
        },
      },
      {
        headerRender: () => 'Office Location',
        accessor: 'officeLocation',
        cellRender: ({ currentPosition }) =>
          StringUtils.withFallback(currentPosition?.location.name),
      },
      {
        headerRender: () => 'Company',
        accessor: 'company',
        cellRender: ({ currentContract }) =>
          StringUtils.withFallback(currentContract?.company.name),
      },
      {
        headerRender: () => 'Business Unit',
        accessor: 'businessUnit',
        cellRender: ({ currentPosition }) =>
          StringUtils.withFallback(currentPosition?.businessUnit.name),
      },
      {
        headerRender: () => 'Department',
        accessor: 'department',
        cellRender: ({ currentPosition }) =>
          StringUtils.withFallback(currentPosition?.department.name),
      },
      {
        headerRender: () => 'Job Title',
        accessor: 'jobTitle',
        cellRender: ({ currentPosition }) =>
          StringUtils.withFallback(currentPosition?.jobTitle),
      },
      {
        headerRender: () => 'Job',
        accessor: 'job',
        cellRender: ({ currentPosition }) =>
          StringUtils.withFallback(currentPosition?.jobTitle),
      },
      {
        headerRender: () => 'Level',
        accessor: 'level',
        cellRender: ({ currentPosition }) =>
          StringUtils.withFallback(currentPosition?.level),
      },
      {
        headerRender: () => 'Primary Manager',
        accessor: 'primaryManager',
        cellRender: ({ generalInformation }) =>
          StringUtils.withFallback(
            generalInformation.primaryManager?.generalInformation
              .preferredFullName
          ),
      },
      {
        headerRender: () => 'Dotted Line Manager',
        accessor: 'dottedLineManager',
        cellRender: ({ generalInformation }) =>
          StringUtils.withFallback(
            generalInformation.dottedLineManager?.generalInformation
              .preferredFullName
          ),
      },
      {
        headerRender: () => 'Nationality',
        accessor: 'nationality',
        cellRender: ({ generalInformation }) =>
          StringUtils.withFallback(generalInformation.nationality.name),
      },
      {
        headerRender: () => 'Google',
        accessor: 'google',
        cellRender: ({ externalAccounts }) => {
          const isGoogleEnabled =
            externalAccounts?.find(
              (account) =>
                account.accountType === EmployeeExternalAccountType.Google
            )?.status === EmployeeExternalAccountStatus.Active;

          return (
            <Badge color={isGoogleEnabled ? 'blue' : 'red'}>
              {isGoogleEnabled ? 'Active' : 'Deactivated'}
            </Badge>
          );
        },
      },
      {
        headerRender: () => 'greenhouse',
        accessor: 'isGreenhouseEnabled',
        cellRender: ({ externalAccounts }) => {
          const isGreenhouseEnabled =
            externalAccounts?.find(
              (account) =>
                account.accountType === EmployeeExternalAccountType.Google
            )?.status === EmployeeExternalAccountStatus.Active;

          return (
            <Badge color={isGreenhouseEnabled ? 'blue' : 'red'}>
              {isGreenhouseEnabled ? 'Active' : 'Deactivated'}
            </Badge>
          );
        },
      },
      {
        headerRender: () => 'Last Working Date',
        accessor: 'lastWorkingDate',
        cellRender: ({ offBoarding }) =>
          DateUtils.formatDate(offBoarding?.lastWorkingDate, 'MMM dd, yyyy'),
      },
      {
        headerRender: () => 'Termination Date',
        accessor: 'terminationDate',
        cellRender: ({ offBoarding }) =>
          DateUtils.formatDate(offBoarding?.employmentEndDate, 'MMM dd, yyyy'),
      },
      {
        headerRender: () => 'Reason for Attrition',
        accessor: 'typeOfAttrition',
        cellRender: ({ offBoarding }) =>
          StringUtils.withFallback(offBoarding?.typeOfAttrition?.name),
      },
      {
        headerRender: () => 'Reason For Leaving',
        accessor: 'reasonForLeaving',
        cellRender: ({ offBoarding }) =>
          StringUtils.withFallback(offBoarding?.reasonForLeaving?.name),
      },
    ],
    []
  );

  return (
    <Table<EmployeeListItem>
      page={page}
      numOfStickyCols={2}
      layout={layout}
      loading={loading}
      data={employees || []}
      notFoundTemplate={
        <NoResult
          title='No Data Found'
          description='Try changing the filters or search terms'
        />
      }
      allowFloatingHeader
      selection={selection}
      onSelectionChange={setSelection}
      selectable={{
        byField: 'id',
        total: employees?.length || 0,
        aside: (
          <Button
            className='bg-blue-6'
            loading={resendLoading}
            onClick={() =>
              resendEmail({ ids: selection.items.map((item) => item.id) })
            }
          >
            Resend Invitation
          </Button>
        ),
      }}
    />
  );
};
