import { Card } from '@/shared/components';
import { ProbationReviewResult } from '@/shared/graphql';
import { StringUtils } from '@/shared/utils';
import { Textarea } from '@any-ui-react/core';
import classNames from 'classnames';
import { differenceInDays } from 'date-fns';
import { useFormContext } from 'react-hook-form';
import { ProbationReviewDetailType } from 'src/types/probation-review-detail';
import { ProbationResult } from './ProbationResult';

interface Props {
  hiredDate: string | null;
  probationEndDate: string | null;
  isSubmitted?: boolean;
  result?: ProbationReviewResult | null;
}

export const TotalTarget = ({
  hiredDate,
  probationEndDate,
  isSubmitted,
  result,
}: Props) => {
  const {
    formState: { errors },
    register,
  } = useFormContext<ProbationReviewDetailType>();

  const overdueDays = probationEndDate
    ? differenceInDays(new Date(), new Date(probationEndDate))
    : 0;

  return (
    <div>
      <div className='my-8 text-lg font-semibold'>Total Target</div>
      <Card className='flex flex-col space-y-4'>
        <div className='flex space-x-1 text-sm'>
          <span>Hired Date:</span>
          <span className='font-semibold'>{hiredDate}</span>
        </div>
        <div className='flex items-end space-x-1 text-sm'>
          <span>Scheduled Probation End Date</span>
          <span className='text-2xs text-gray-7'>
            (Review Deadline for the Last Review)
          </span>
          :<span className='font-bold'>{probationEndDate}</span>
        </div>

        {isSubmitted && result && (
          <div className='flex items-center space-x-1 text-sm'>
            <span>Review Result:</span> <ProbationResult result={result} />
          </div>
        )}

        <div className='relative'>
          <Textarea
            {...register('target')}
            label='Target'
            required
            minRows={5}
            error={errors.target?.message}
            disabled={isSubmitted}
            classNames={{
              input: classNames({ '!text-gray-9': isSubmitted }),
            }}
          />

          {!isSubmitted && overdueDays > 0 && (
            <span className='absolute right-0 top-0 text-red-5'>
              Overdue {overdueDays} {StringUtils.pluralize(overdueDays, 'day')}
            </span>
          )}
        </div>
      </Card>
    </div>
  );
};
